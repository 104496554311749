<template>
  <CContainer class="out-of-office-view datatable-container">
    <Loader :is-processing="state.isLoading" />

    <CRow class="mt-4">
      <CCol md="12">
        <ActionPanel
          show-export
          show-search
          @on-search="searchByQuery"
          @on-export="
            handleOutOfOfficePasses(state.pagination.activePage, true)
          "
        ></ActionPanel>
        <div
          class="status-guides-container justify-content-center justify-content-sm-end mb-1"
        >
          <PerPage
            v-if="state.pagination"
            :pagination="state.pagination"
            :showing-entries="true"
            @on-page-change="setPerPage"
          />
        </div>

        <DataTable
          v-if="outOfOfficePasses"
          :items="outOfOfficePasses"
          :fields="state.dataTableFields"
          :sorter="{ external: true }"
          column-filter
          :no-items-view="{
            noResults: 'No items available',
            noItems: 'No items available'
          }"
          @update:sorter-value="sortByColumn"
        >
          <template #search_column>
            <div class="sort-icon-v3">
              <i class="ri-search-line" @click="toggleSearchByColumn"></i>
            </div>
          </template>
          <template #from_date="{ item }">
            <td>
              <span class="v3base-gray">
                {{
                  item.initial_from_date
                    ? $helpers.transformDate(
                        item.initial_from_date,
                        "MM/DD/YYYY"
                      )
                    : "-"
                }}</span
              >
            </td>
          </template>
          <template #from_date-filter>
            <input
              v-show="state.filter"
              v-model="state.columnSearchKeys.from_date.value"
              placeholder="Search by date"
              class="form-control form-control-sm my-2"
              @input="searchByQuery($event.target.value, 'column')"
            />
          </template>

          <template #unavailable="{ item }">
            <td v-if="item.unavailable">
              <p
                v-if="item.unavailable_type === 'App\\Models\\User'"
                class="m-0"
              >
                <span class="v3base-gray">
                  {{
                    item.unavailable.first_name +
                    " " +
                    item.unavailable.last_name
                  }}
                </span>
              </p>
              <p v-else class="m-0">
                <span class="v3base-gray">
                  {{ item.unavailable.name }}
                </span>
              </p>
            </td>
            <td v-else>-</td>
          </template>
          <template #unavailable-filter>
            <input
              v-show="state.filter"
              v-model="state.columnSearchKeys.unavailable.value"
              placeholder="Search by name"
              class="form-control form-control-sm my-2"
              @input="searchByQuery($event.target.value, 'column')"
            />
          </template>

          <template #start_hour="{ item }">
            <td>
              <b>{{
                item.from_date
                  ? $helpers.transformDate(item.from_date, "h:mm A")
                  : "-"
              }}</b>
            </td>
          </template>
          <template #start_hour-filter>
            <input
              v-show="state.filter"
              v-model="state.columnSearchKeys.start_hour.value"
              placeholder="Search by time"
              class="form-control form-control-sm my-2"
              @input="searchByQuery($event.target.value, 'column')"
            />
          </template>

          <template #end_hour="{ item }">
            <td>
              <b>{{
                item.to_date
                  ? $helpers.transformDate(item.to_date, "h:mm A")
                  : "-"
              }}</b>
            </td>
          </template>
          <template #end_hour-filter>
            <input
              v-show="state.filter"
              v-model="state.columnSearchKeys.end_hour.value"
              placeholder="Search by time"
              class="form-control form-control-sm my-2"
              @input="searchByQuery($event.target.value, 'column')"
            />
          </template>

          <template #recurrence_days="{ item }">
            <td>
              <div v-if="item.recurrence_type" class="d-flex v3base-gray">
                <div class="me-2">
                  {{ item.recurrence_type }}
                </div>
                <div
                  v-if="item.recurrence_days"
                  class="weekdays-options-container d-flex"
                >
                  <div
                    v-for="(day, index) in JSON.parse(item.recurrence_days)
                      .days"
                    :key="index"
                    class="weekday-box active me-1"
                  >
                    {{ day.substring(0, 2) }}
                  </div>
                </div>
              </div>
              <div class="font-smaller v3base-gray" v-if="item.recurrence_type">
                {{ $helpers.transformDate(item.from_date, "MM/D/YY") }}
                <i class="ri-arrow-right-s-line v3base-blue"></i>
                {{
                  $helpers.transformDate(
                    item.recurrence_end_at + " 12:00:00",
                    "MM/D/YY"
                  )
                }}
              </div>
              <div class="v3base-gray" v-else>does not repeat</div>
            </td>
          </template>
          <template #recurrence_days-filter>
            <input
              v-show="state.filter"
              v-model="state.columnSearchKeys.recurrence_days.value"
              placeholder="Search by date"
              class="form-control form-control-sm my-2"
              @input="searchByQuery($event.target.value, 'column')"
            />
          </template>

          <template #comment="{ item }">
            <td class="v3comments-column">
              <div
                v-if="item.comment"
                class="v3comments-list"
                :class="!item.status ? 'v3comments-list-inactive' : ''"
              >
                <div class="v3comment">
                  {{ item.comment }}
                </div>
              </div>
            </td>
          </template>
          <template #comment-filter>
            <input
              v-show="state.filter"
              v-model="state.columnSearchKeys.comment.value"
              placeholder="Search by latest comment"
              class="form-control form-control-sm my-2"
              @input="searchByQuery($event.target.value, 'column')"
            />
          </template>

          <template #action="{ item }">
            <td class="text-end">
              <Actions
                v-if="!item.status"
                :actions="aptActionsWithoutStatus"
                data-test-id="ooo-actions-btn"
                @click-action="handleTableAction($event, item)"
              >
              </Actions>
              <Actions
                v-else
                :actions="aptActions"
                data-test-id="ooo-actions-btn"
                @click-action="handleTableAction($event, item)"
              >
              </Actions>
            </td>
          </template>
          <template #action-filter>
            <div v-show="state.filter"></div>
          </template>
        </DataTable>

        <Pagination
          v-if="
            state.pagination.pages &&
            state.pagination.pages > 1 &&
            !state.isLazyLoadingMode
          "
          class="cs-pagination mt-4"
          :dots="false"
          :double-arrows="true"
          :active-page="state.pagination.activePage"
          :pages="state.pagination.pages"
          size="lg"
          align="center"
          @update:active-page="setActivePage"
        >
        </Pagination>
      </CCol>
    </CRow>
  </CContainer>
</template>
<script>
import { onMounted, computed, reactive, inject, watch } from "vue"
import { useStore } from "vuex"
import Loader from "@/v3components/shared/Loader/Loader.vue"
import ActionPanel from "@/v3components/shared/DataTable/ActionPanel.vue"
import DataTable from "@/v3components/shared/DataTable/DataTable.vue"
import PerPage from "@/v3components/shared/DataTable/Perpage.vue"
import Pagination from "@/v3components/shared/DataTable/Pagination.vue"
import Actions from "@/v3components/shared/DataTable/Actions.vue"
import OutOfOfficeForm from "@/v3components/Forms/OutOfOfficeForm.vue"
import helpers from "@/helpers/index.js"
import download from "@/helpers/downloadCSV.js"

export default {
  name: "OutOfOfficeTable",
  components: {
    Loader,
    ActionPanel,
    PerPage,
    Pagination,
    DataTable,
    Actions
  },
  setup() {
    const store = useStore()

    const actionDialog = inject("actionDialog")
    const modal = inject("modal")

    const state = reactive({
      columnSearchKeys: {
        globalQuery: "",
        from_date: {
          value: "",
          column: ["start_date_string"],
          sortBy: "from_date"
        },
        unavailable: {
          value: "",
          column: ["unavailable.sortable_name"],
          sortBy: "unavailable.sortable_name"
        },
        start_hour: {
          value: "",
          column: ["start_hour.raw"],
          sortBy: "start_hour"
        },
        end_hour: { value: "", column: ["end_hour.raw"], sortBy: "end_hour" },
        recurrence_days: {
          value: "",
          column: ["recurrence_type"],
          sortBy: "recurrence_type"
        },
        comment: { value: "", column: ["comment"], sortBy: "comment" }
      },
      filterParams: {
        search_query: "",
        sort_query: ""
      },
      pagination: {
        activePage: 1,
        total: 0,
        to: 0,
        pages: 0,
        per_page: { label: "25", value: 25 }
      },
      dataTableFields: [
        { key: "from_date", label: "Date", _style: "min-width:130px" },
        {
          key: "unavailable",
          _style: "min-width:190px",
          label: "Teacher / Location"
        },
        { key: "start_hour", label: "Start", _style: "min-width:130px" },
        { key: "end_hour", label: "End", _style: "min-width:130px" },
        {
          key: "recurrence_days",
          label: "Recurrence",
          _style: "min-width:180px"
        },
        { key: "comment", Label: "Comment", _style: "min-width:200px" },
        {
          key: "action",
          label: "Action",
          _style: "width: 50px;"
        }
      ],
      tableTabs: [{ label: "All out of office requests", value: "all" }],
      filter: false,
      isLoading: false,
      isLazyLoadingMode: false
    })

    const outOfOfficePasses = computed(
      () => store.getters["outOfOfficePasses/outOfOfficePasses"]
    )

    watch(
      () => modal.options.action,
      (isAction) => {
        if (isAction == "createOutOfOffice") {
          handleOutOfOfficePasses()
        }
      }
    )

    onMounted(() => {
      handleOutOfOfficePasses()
      initLazyLoading()
    })

    const handleOutOfOfficePasses = (
      page,
      isExport,
      isDelete,
      isLazyLoadingMode,
      noLoader
    ) => {
      if (isDelete && page) {
        state.pagination.total = state.pagination.total - 1
        state.pagination.pages = Math.ceil(
          state.pagination.total / state.pagination.per_page.value
        )
        state.pagination.activePage =
          page < state.pagination.pages ? page : state.pagination.pages
      } else {
        state.pagination.activePage = page ? page : 1
      }
      const params = {
        page: state.pagination.activePage,
        per_page: state.pagination.per_page.value
      }
      if (state.filterParams.search_query) {
        params.search_query = state.filterParams.search_query
      }
      if (state.filterParams.sort_query) {
        params.sort = state.filterParams.sort_query
      }
      if (isExport) {
        exportCSVOutOfOfficesPasses(params)
      } else {
        getOutOfOfficePasses(params, isLazyLoadingMode, noLoader)
      }
    }

    const getOutOfOfficePasses = (params, isLazyLoadingMode, noLoader) => {
      state.isLoading = !noLoader
      store
        .dispatch("outOfOfficePasses/getOutOfOfficePasses", params)
        .then((response) => {
          const data = response.data.data
          if (data.unavailables) {
            if (isLazyLoadingMode) {
              store.commit(
                "outOfOfficePasses/PUSH_OUTOFOFFICE_PASSES",
                data.unavailables.data
              )
            } else {
              store.commit(
                "outOfOfficePasses/SET_OUTOFOFFICE_PASSES",
                data.unavailables.data
              )
            }
            state.pagination.total = data.unavailables.total
            state.pagination.from = data.unavailables.from
            state.pagination.to = data.unavailables.to
            state.pagination.pages = Math.ceil(
              data.unavailables.total / data.unavailables.per_page
            )
            state.pagination.activePage = Number(state.pagination.activePage)
            state.pagination = JSON.parse(JSON.stringify(state.pagination))
          }
          state.isLoading = false
        })
        .catch(() => {
          state.isLoading = false
        })
    }

    const exportCSVOutOfOfficesPasses = (params) => {
      params.per_page = [25, 50, 100].includes(state.pagination.per_page.value)
        ? state.pagination.per_page.value
        : "all"
      state.isLoading = true
      store
        .dispatch("outOfOfficePasses/getCSVOutOfOfficePassesExport", params)
        .then((response) => {
          if (response.data) {
            download.CSVExport(response.data, "out-of-office-passes")
          }
          state.isLoading = false
        })
        .catch(() => {
          state.isLoading = false
        })
    }

    const searchByQuery = (value, type) => {
      clearInterval(state.searchTimeOut)
      state.searchTimeOut = setTimeout(() => {
        handleQuerySearch(value, type)
      }, 500)
    }

    const handleQuerySearch = (value, type) => {
      const query = value.toString()
      if (
        query.replace(/\s/g, "").length > 0 ||
        query.replace(/\s/g, "").length === 0
      ) {
        state.filterParams.search_query = ""
        if (type && type === "column") {
          state.columnSearchKeys.globalQuery = ""
          for (const key in state.columnSearchKeys) {
            if (Object.hasOwnProperty.call(state.columnSearchKeys, key)) {
              const columnData = state.columnSearchKeys[key]
              if (columnData.value) {
                columnData.column.forEach((col) => {
                  state.filterParams.search_query =
                    col === "unavailable.sortable_name"
                      ? state.filterParams.search_query +
                        `${col}:"${columnData.value}"|unavailable.name:"${columnData.value}", `
                      : state.filterParams.search_query +
                        `${col}:"${columnData.value}", `
                })
              }
            }
          }
          state.filterParams.search_query =
            state.filterParams.search_query.slice(0, -2)
        } else {
          resetSearchQuery()
          state.columnSearchKeys.globalQuery = query
          state.filterParams.search_query = query ? '"' + query + '"' : query
        }
        handleOutOfOfficePasses()
      }
    }

    const sortByColumn = (column) => {
      const columnName = state.columnSearchKeys[column.column]
        ? state.columnSearchKeys[column.column].sortBy
        : column.column
      state.filterParams.sort_query = `${columnName}:${
        column.asc ? "asc" : "desc"
      }`
      handleOutOfOfficePasses()
    }

    const deleteOutOfOffice = (passID) => {
      if (passID && confirm("Are you sure you want to delete this item?")) {
        state.isLoading = true
        store
          .dispatch("outOfOfficePasses/deleteOutOfOffice", passID)
          .then(() => {
            state.isLoading = false
            handleOutOfOfficePasses(state.pagination.activePage, false, true)
          })
          .catch(() => (state.isLoading = false))
      }
    }

    const updateOutOfOfficeStatus = (pass) => {
      if (pass.id && pass.status) {
        state.isLoading = true
        store
          .dispatch("outOfOfficePasses/updateOutOfOfficeStatus", {
            id: pass.id
          })
          .then(() => {
            store.commit("outOfOfficePasses/SET_EDITABLE_PASS", null)
            state.isLoading = false
          })
          .catch(() => (state.isLoading = false))
      }
    }

    const setPerPage = (option) => {
      if (option) {
        state.isLazyLoadingMode = option.label === "All entries"
        state.pagination.per_page = option
        handleOutOfOfficePasses()
      }
    }

    const setActivePage = (page) => {
      state.pagination.activePage = page
      handleOutOfOfficePasses(state.pagination.activePage)
      helpers.scrollToTop()
    }

    const resetSearchQuery = () => {
      state.filterParams.search_query = ""
      state.columnSearchKeys = {
        globalQuery: "",
        from_date: {
          value: "",
          column: ["start_date_string"],
          sortBy: "from_date"
        },
        unavailable: {
          value: "",
          column: ["unavailable.sortable_name"],
          sortBy: "unavailable.sortable_name"
        },
        start_hour: {
          value: "",
          column: ["start_hour.raw"],
          sortBy: "start_hour"
        },
        end_hour: { value: "", column: ["end_hour.raw"], sortBy: "end_hour" },
        recurrence_days: {
          value: "",
          column: ["recurrence_type"],
          sortBy: "recurrence_type"
        },
        comment: { value: "", column: ["comment"], sortBy: "comment" }
      }
    }

    const initLazyLoading = () => {
      const body = document.getElementsByClassName("main-scroll-container")[0]
      if (body) {
        body.onscroll = () => {
          if (
            state.isLazyLoadingMode &&
            outOfOfficePasses.value &&
            outOfOfficePasses.value.length &&
            state.pagination.total > outOfOfficePasses.value.length
          ) {
            if (body.offsetHeight + body.scrollTop + 1 >= body.scrollHeight) {
              state.pagination.activePage = state.pagination.activePage + 1
              handleOutOfOfficePasses(
                state.pagination.activePage,
                false,
                false,
                true
              )
            }
          }
        }
      }
    }

    const toggleSearchByColumn = () => {
      state.filter = !state.filter
    }

    const aptActions = [
      { label: "Edit", icon: "ri-edit-line", action: "edit" },
      { label: "End", icon: "ri-close-circle-line", action: "end" },
      {
        label: "Delete",
        icon: "ri-delete-bin-line",
        class: "important",
        action: "delete"
      }
    ]

    const aptActionsWithoutStatus = [
      {
        label: "Delete",
        icon: "ri-delete-bin-line",
        class: "important",
        action: "delete"
      }
    ]

    const handleTableAction = (event, item) => {
      if (event.action == "delete") {
        actionDialog.open(deleteOutOfOffice, {
          args: item.id,
          props: { danger: true, title: "Delete", question: "Are you sure?" }
        })
      }
      if (event.action == "edit") {
        modal.open(OutOfOfficeForm, {
          size: "md",
          title: "Edit out of office",
          props: {
            editablePass: item,
            isInView: true
          }
        })
      }
      if (event.action == "end") {
        updateOutOfOfficeStatus(item)
      }
    }

    return {
      state,
      outOfOfficePasses,
      handleOutOfOfficePasses,
      searchByQuery,
      handleQuerySearch,
      sortByColumn,
      setPerPage,
      setActivePage,
      resetSearchQuery,
      toggleSearchByColumn,
      aptActions,
      aptActionsWithoutStatus,
      handleTableAction
    }
  }
}
</script>
