<template>
  <div class="mb-5">
    <div
      v-if="userRole !== 'student'"
      class="row profile-settings justify-content-center"
    >
      <ActionPanel @tab-clicked="setActiveTab" :tabs="tabs"> </ActionPanel>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-8 v3-data-table">
        <div v-if="state.activeTab == 'my-profile'">
          <div v-if="!isLoading">
            <div
              v-if="
                !!state.activeSchool?.show_student_photos ||
                userRole !== 'student'
              "
            >
              <Avatar
                :user="user"
                :user-role="userRole"
                :show-controls="false"
              />
            </div>
            <div v-else>
              <Avatar
                :user="user"
                :user-role="userRole"
                :show-controls="false"
              />
            </div>
            <div>
              <UserProfileDetails
                :user="user"
                :avatar="avatar"
                :user-role="userRole"
                :active-appoinment-module-options="
                  activeAppoinmentModuleOptions
                "
                :is-active-appoinment-module="isActiveAppoinmentModule"
                :is-active-kiosk-module="isActiveKioskModule"
              />
            </div>
          </div>
          <div v-else class="d-flex justify-content-center">
            <CSpinner color="primary" />
          </div>
        </div>
        <div v-if="userRole !== 'student' && state.activeTab == 'affiliations'">
          <UserLocationDetails :user="user"></UserLocationDetails>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, onMounted, ref } from "vue"
import { useStore } from "vuex"
import ActionPanel from "@/v3components/shared/DataTable/ActionPanel.vue"
import Avatar from "@/v3views/UserSettings/Avatar.vue"
import UserProfileDetails from "./UserProfileDetails.vue"
import UserLocationDetails from "./UserLocationDetails.vue"

export default {
  name: "UserDetails",
  components: {
    ActionPanel,
    Avatar,
    UserProfileDetails,
    UserLocationDetails
  },
  props: {
    user: {
      type: Object
    },
    userRole: {
      type: String,
      default: ""
    },
    activeAppoinmentModuleOptions: {
      type: Object
    },
    isActiveAppoinmentModule: {
      type: Boolean,
      default: false
    },
    isActiveKioskModule: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const store = useStore()
    const state = reactive({
      avatar: null,
      activeTab: null,
      activeSchool: null,
      form: {
        locations: []
      }
    })

    const isLoading = ref(true)

    const tabs = [
      {
        label: "Profile",
        value: "my-profile"
      },
      {
        label: "Locations",
        value: "affiliations"
      }
    ]

    onMounted(() => {
      isLoading.value = true
      store
        .dispatch("schools/getSchools", { clearCacheEntry: true })
        .then((response) => {
          state.activeSchool = response?.data?.data?.[0] || state.activeSchool
          isLoading.value = false
        })

      state.activeTab = tabs[0].value
    })

    const setActiveTab = (tab) => {
      state.activeTab = tab.value
    }

    return {
      state,
      tabs,
      setActiveTab,
      isLoading
    }
  }
}
</script>
