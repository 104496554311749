<template>
  <div class="pass-history-view v3-pass-history">
    <Loader :is-processing="state.isLoading" />
    <ActionPanel
      class="mb-3"
      :tabs="activeTableTabs"
      show-sort
      :show-export="isNowReportsTab ? true : false"
      show-top-left-slot
      @on-sort="toggleAdvancedFiltersPanel"
      @on-export="exportCSV"
      @tab-clicked="setActiveTab"
    >
      <template #first-top-left-content>
        <CustomSelect
          class="select-width-350"
          v-model="state.filterParams.aggregate_by"
          :clearable="false"
          :searchable="false"
          :close-on-select="true"
          :options="aggregateByOptions"
          placeholder="Choose One"
          :reduce="(item) => item.value"
          :invalid-feedback="errors.aggregate_by"
          @update:model-value="switchAggregateBy"
        ></CustomSelect>
      </template>

      <template #right-last-content>
        <Dropdown
          v-if="
            state.filterParams.aggregate_by == 'highest_pass_takers' &&
            isNowReportsTab
          "
          auto-close="outside"
          @update:model-value="onColumnOptionsChange"
          :items="activeTableDropdowns"
          :check-list-mode="true"
          class="left-box-separator"
        >
          <template #toggler>
            <div class="d-flex align-items-center">
              <IconButton class="static" icon="ri-table-line"></IconButton>
              <span>Columns</span>
            </div>
          </template>
        </Dropdown>
      </template>

      <template #bottom-content>
        <div class="mt-3" v-if="state.isAdFiltersPanelVisible">
          <div class="d-flex flex-column mb-3">
            <div class="d-flex mb-3">
              <LazyLoadSelect
                class="flex-basis-100 ms-1 me-2"
                :selected="state.filterParams.teacher_ids"
                :is-multiple="true"
                type="adults"
                placeholder="Search Teachers"
                :invalid-feedback="errors.teacher_ids"
                select-only-names
                :show-inactive="true"
                @changed="
                  (value) => {
                    state.filterParams.teacher_ids = value
                  }
                "
              ></LazyLoadSelect>
              <LazyLoadSelect
                class="flex-basis-100 ms-1 me-2"
                :selected="state.filterParams.student_ids"
                :is-multiple="true"
                type="student"
                placeholder="Search Student"
                :invalid-feedback="errors.student_ids"
                select-only-names
                :show-inactive="true"
                @changed="
                  (value) => {
                    state.filterParams.student_ids = value
                  }
                "
              ></LazyLoadSelect>
              <LazyLoadSelect
                class="flex-basis-100 ms-1 me-2"
                :selected="state.filterParams.room_ids"
                :is-multiple="true"
                type="locations"
                placeholder="Search Locations (rooms)"
                :invalid-feedback="errors.room_ids"
                @changed="
                  (value) => {
                    state.filterParams.room_ids = value
                  }
                "
              ></LazyLoadSelect>
              <CustomSelect
                class="flex-basis-100 ms-1 me-2"
                v-model="state.filterParams.by_pass_type"
                :close-on-select="true"
                :options="passTypesOptions"
                placeholder="By Pass Type"
                :reduce="(item) => item.value"
                :invalid-feedback="errors.by_pass_type"
              ></CustomSelect>
            </div>
            <div class="d-flex">
              <CustomSelect
                v-if="state.gradYears"
                class="flex-basis-100 ms-1 me-2"
                v-model="state.filterParams.by_grad_year"
                :close-on-select="true"
                :multiple="true"
                :options="state.gradYears"
                placeholder="By Grad Year"
                :invalid-feedback="errors.by_grad_year"
              ></CustomSelect>

              <CustomSelect
                v-if="gradeYears"
                v-model="state.filterParams.by_school_year"
                class="flex-basis-100 ms-1 me-2"
                :options="gradeYears"
                placeholder="Select year"
                :close-on-select="true"
                :invalid-feedback="errors.by_school_year"
                @update:model-value="setCalendarGradeYears"
              ></CustomSelect>

              <CustomSchoolYearSelectDatePicker
                class="flex-basis-100 ms-1 me-2"
                v-model="state.filterParams.dates"
                :range-options="optionalDateRanges"
                :is-current-school-year-selected="currentSchoolYearSelected"
                :selected-school-year-limit="state.filterParams.by_school_year"
              ></CustomSchoolYearSelectDatePicker>

              <InputField
                class="flex-basis-100 ms-1 me-2"
                prepend-icon="ri-search-line"
                placeholder="Search"
                :model-value="state.watchSearchQuery"
                @update:model-value="searchByQuery"
              ></InputField>
            </div>
          </div>
          <div class="justify-content-end text-end">
            <CAlert v-if="state.errors && !state.isLoading" color="danger">
              {{ state.errors }}
            </CAlert>
            <BaseButton solid rounded @click="sendReport()">{{
              isNowReportsTab ? "Search" : "Create"
            }}</BaseButton>
            <BaseButton class="ms-2" rounded @click="clearFilter()"
              >Clear all</BaseButton
            >
          </div>
        </div>
      </template>
    </ActionPanel>

    <PerPage
      v-if="state.pagination && state.pagination.total > 0"
      :pagination="state.pagination"
      :showing-entries="true"
      @on-page-change="setPerPage"
    />

    <DataTable
      v-if="activeDataTableItems"
      class="datatable bg-white text-center mt-3"
      add-table-classes="contactTracingDataTable"
      :fields="activeTableFields"
      :items="activeDataTableItems"
      :sorter="{ external: true }"
      column-filter
      :no-items-view="{
        noResults: 'No items available',
        noItems: 'No items available'
      }"
      @update:sorter-value="sortByColumn"
    >
      <template #search_column>
        <div class="sort-icon-v3">
          <i class="ri-search-line" @click="toggleSearchByColumn"></i>
        </div>
      </template>

      <!-- DataTable Fields for Now Reports -->
      <template #student_sis_id="{ item }">
        <td>
          <div>
            {{
              item.destination.student_sis_id
                ? item.destination.student_sis_id
                : "null"
            }}
          </div>
        </td>
      </template>
      <template #destination="{ item }">
        <td class="text-start">
          {{ item.destination ? item.destination.name : "" }}
        </td>
      </template>
      <template #destination-filter>
        <input
          v-show="state.filter"
          v-model="state.columnSearchKeys.destination.value"
          placeholder="Search by name"
          class="form-control form-control-sm my-2"
          @input="searchByQuery($event.target.value, 'column')"
        />
      </template>

      <template #total_passes-filter>
        <input
          v-show="state.filter"
          v-model="state.columnSearchKeys.total_passes.value"
          placeholder="Search by passes"
          class="form-control form-control-sm my-2"
          @input="searchByQuery($event.target.value, 'column')"
        />
      </template>

      <template #total_time_all="{ item }">
        <td>
          {{ item.total_time_all ? item.total_time_all : "00:00:00" }}
        </td>
      </template>
      <template #total_time_all-filter>
        <input
          v-show="state.filter"
          v-model="state.columnSearchKeys.total_time_all.value"
          placeholder="Search by time"
          class="form-control form-control-sm my-2"
          @input="searchByQuery($event.target.value, 'column')"
        />
      </template>

      <template #total_time_student="{ item }">
        <td>
          {{ item.total_time_student ? item.total_time_student : "00:00:00" }}
        </td>
      </template>
      <template #total_time_student-filter>
        <input
          v-show="state.filter"
          v-model="state.columnSearchKeys.total_time_student.value"
          placeholder="Search by time"
          class="form-control form-control-sm my-2"
          @input="searchByQuery($event.target.value, 'column')"
        />
      </template>

      <template #total_time_aup="{ item }">
        <td>
          {{ item.total_time_aup ? item.total_time_aup : "00:00:00" }}
        </td>
      </template>
      <template #total_time_aup-filter>
        <input
          v-show="state.filter"
          v-model="state.columnSearchKeys.total_time_aup.value"
          placeholder="Search by time"
          class="form-control form-control-sm my-2"
          @input="searchByQuery($event.target.value, 'column')"
        />
      </template>

      <template #total_time_proxy="{ item }">
        <td>
          {{ item.total_time_proxy ? item.total_time_proxy : "00:00:00" }}
        </td>
      </template>
      <template #total_time_proxy-filter>
        <input
          v-show="state.filter"
          v-model="state.columnSearchKeys.total_time_proxy.value"
          placeholder="Search by time"
          class="form-control form-control-sm my-2"
          @input="searchByQuery($event.target.value, 'column')"
        />
      </template>

      <template #total_time_apt="{ item }">
        <td>
          {{ item.total_time_apt ? item.total_time_apt : "00:00:00" }}
        </td>
      </template>
      <template #total_time_apt-filter>
        <input
          v-show="state.filter"
          v-model="state.columnSearchKeys.total_time_apt.value"
          placeholder="Search by time"
          class="form-control form-control-sm my-2"
          @input="searchByQuery($event.target.value, 'column')"
        />
      </template>

      <template #total_time_ksk="{ item }">
        <td>
          {{ item.total_time_ksk ? item.total_time_ksk : "00:00:00" }}
        </td>
      </template>
      <template #total_time_ksk-filter>
        <input
          v-show="state.filter"
          v-model="state.columnSearchKeys.total_time_ksk.value"
          placeholder="Search by time"
          class="form-control form-control-sm my-2"
          @input="searchByQuery($event.target.value, 'column')"
        />
      </template>

      <!-- DataTable Fields for Background Reports -->
      <template #aggregate_by_label="{ item }">
        <td>
          {{ item.aggregate_by_label }}
        </td>
      </template>
      <template #aggregate_by_label-filter>
        <input
          v-show="state.filter"
          v-model="state.columnSearchKeys.aggregate_by_label.value"
          placeholder="Search by report type"
          class="form-control form-control-sm my-2"
          @input="searchByQuery($event.target.value, 'column')"
        />
      </template>

      <template #teachers="{ item }">
        <td>
          {{ namesById(item.teachers) }}
        </td>
      </template>
      <template #teachers-filter>
        <input
          v-show="state.filter"
          v-model="state.columnSearchKeys.teachers.value"
          placeholder="Search by teacher"
          class="form-control form-control-sm my-2"
          @input="searchByQuery($event.target.value, 'column')"
        />
      </template>

      <template #students="{ item }">
        <td>
          {{ namesById(item.students) }}
        </td>
      </template>
      <template #students-filter>
        <input
          v-show="state.filter"
          v-model="state.columnSearchKeys.students.value"
          placeholder="Search by student"
          class="form-control form-control-sm my-2"
          @input="searchByQuery($event.target.value, 'column')"
        />
      </template>

      <template #rooms="{ item }">
        <td>
          {{ namesById(item.rooms) }}
        </td>
      </template>
      <template #rooms-filter>
        <input
          v-show="state.filter"
          v-model="state.columnSearchKeys.rooms.value"
          placeholder="Search by destination"
          class="form-control form-control-sm my-2"
          @input="searchByQuery($event.target.value, 'column')"
        />
      </template>

      <template #by_pass_type="{ item }">
        <td>
          {{ item.by_pass_type }}
        </td>
      </template>
      <template #by_pass_type-filter>
        <input
          v-show="state.filter"
          v-model="state.columnSearchKeys.by_pass_type.value"
          placeholder="Search pass type"
          class="form-control form-control-sm my-2"
          @input="searchByQuery($event.target.value, 'column')"
        />
      </template>

      <template #by_grad_years="{ item }">
        <td>
          {{ by_grad_year(item.by_grad_years) }}
        </td>
      </template>
      <template #by_grad_years-filter>
        <input
          v-show="state.filter"
          v-model="state.columnSearchKeys.by_grad_years.value"
          placeholder="Search by grad year"
          class="form-control form-control-sm my-2"
          @input="searchByQuery($event.target.value, 'column')"
        />
      </template>

      <template #by_school_year="{ item }">
        <td>
          {{ item.by_school_year }}
        </td>
      </template>
      <template #by_school_year-filter>
        <input
          v-show="state.filter"
          v-model="state.columnSearchKeys.by_school_year.value"
          placeholder="Search by school year"
          class="form-control form-control-sm my-2"
          @input="searchByQuery($event.target.value, 'column')"
        />
      </template>

      <template #dates="{ item }">
        <td>
          {{ item.dates }}
        </td>
      </template>
      <template #dates-filter>
        <input
          v-show="state.filter"
          v-model="state.columnSearchKeys.dates.value"
          placeholder="Search by dates"
          class="form-control form-control-sm my-2"
          @input="searchByQuery($event.target.value, 'column')"
        />
      </template>

      <template #requested_at="{ item }">
        <td>
          {{ transformRequestedAt(item.requested_at) }}
        </td>
      </template>
      <template #requested_at-filter>
        <input
          v-show="state.filter"
          v-model="state.columnSearchKeys.requested_at.value"
          placeholder="Search by request date"
          class="form-control form-control-sm my-2"
          @input="searchByQuery($event.target.value, 'column')"
        />
      </template>

      <template #runned_at="{ item }">
        <td>
          {{
            item.runned_at == null ? "-" : transformRequestedAt(item.runned_at)
          }}
        </td>
      </template>
      <template #runned_at-filter>
        <input
          v-show="state.filter"
          v-model="state.columnSearchKeys.runned_at.value"
          placeholder="Search by last run date"
          class="form-control form-control-sm my-2"
          @input="searchByQuery($event.target.value, 'column')"
        />
      </template>

      <template #requested_by="{ item }">
        <td>
          {{ namesById(item.requested_by) }}
        </td>
      </template>
      <template #requested_by-filter>
        <input
          v-show="state.filter"
          v-model="state.columnSearchKeys.requested_by.value"
          placeholder="Search by request by"
          class="form-control form-control-sm my-2"
          @input="searchByQuery($event.target.value, 'column')"
        />
      </template>

      <template #runned_by="{ item }">
        <td>
          {{ item.runned_by == null ? "-" : Object.values(item.runned_by)[0] }}
        </td>
      </template>
      <template #runned_by-filter>
        <input
          v-show="state.filter"
          v-model="state.columnSearchKeys.runned_by.value"
          placeholder="Search by run by"
          class="form-control form-control-sm my-2"
          @input="searchByQuery($event.target.value, 'column')"
        />
      </template>

      <template #status="{ item }">
        <td>
          <StatusPill :status="'STATUS_' + item.status"> </StatusPill>
        </td>
      </template>
      <template #status-filter>
        <input
          v-show="state.filter"
          v-model="state.columnSearchKeys.status.value"
          placeholder="Search by status"
          class="form-control form-control-sm my-2"
          @input="searchByQuery($event.target.value, 'column')"
        />
      </template>

      <template #action="{ item }">
        <td>
          <div class="d-flex">
            <Actions
              :actions="getReportActions(item)"
              @click-action="handleTableAction($event, item)"
            >
            </Actions>
          </div>
        </td>
      </template>
      <template #action-filter>
        <div></div>
      </template>
    </DataTable>

    <Pagination
      v-if="state.pagination.pages > 1 && !state.isLazyLoadingMode"
      :active-page="state.pagination.activePage"
      :pages="state.pagination.pages"
      class="cs-pagination mt-4"
      :dots="false"
      :double-arrows="true"
      size="lg"
      align="center"
      @update:active-page="setActivePage"
    ></Pagination>
  </div>
</template>

<script>
import { reactive, computed, onMounted, inject } from "vue"
import { useStore } from "vuex"
import { helpers, required } from "@vuelidate/validators"
import useVuelidate from "@vuelidate/core"
import moment from "moment-timezone"
import Loader from "@/v3components/shared/Loader/Loader.vue"
import ActionPanel from "@/v3components/shared/DataTable/ActionPanel.vue"
import Dropdown from "@/v3components/shared/Buttons/Dropdown.vue"
import IconButton from "@/v3components/shared/Buttons/IconButton.vue"
import BaseButton from "@/v3components/shared/Buttons/BaseButton.vue"
import CustomSelect from "@/v3components/shared/Form/CustomSelect.vue"
import LazyLoadSelect from "@/v3components/shared/Form/LazyLoadSelect.vue"
import CustomSchoolYearSelectDatePicker from "@/v3components/shared/Form/CustomSchoolYearSelectDatePicker.vue"
import InputField from "@/v3components/shared/Form/InputField.vue"
import PerPage from "@/v3components/shared/DataTable/Perpage.vue"
import DataTable from "@/v3components/shared/DataTable/DataTable.vue"
import StatusPill from "@/v3components/shared/DataTable/StatusPill.vue"
import Actions from "@/v3components/shared/DataTable/Actions.vue"
import Pagination from "@/v3components/shared/DataTable/Pagination.vue"
import InfoBox from "@/v3components/shared/Alerts/InfoBox.vue"
import download from "@/helpers/downloadCSV.js"
import tableStaticData from "./static-data.js"
import helpersJS from "@/helpers/index.js"

export default {
  name: "SummaryReportsTable",
  components: {
    Loader,
    ActionPanel,
    Dropdown,
    IconButton,
    BaseButton,
    CustomSelect,
    LazyLoadSelect,
    CustomSchoolYearSelectDatePicker,
    InputField,
    PerPage,
    DataTable,
    StatusPill,
    Actions,
    Pagination
  },
  setup() {
    const store = useStore()
    const actionDialog = inject("actionDialog")
    const modal = inject("modal")

    const state = reactive({
      ...tableStaticData,
      isLazyLoadingMode: false,
      isLoading: false,
      isAdFiltersPanelVisible: true,
      showStudentNumber: false,
      gradYears: [],
      filter: false,
      searchTimeOut: null,
      watchSearchQuery: "",
      pagination: {
        activePage: 1,
        total: 0,
        pages: 0,
        per_page: { label: "25", value: 25 }
      },
      filterParams: {
        activeTab: "",
        search_id: "",
        search_query: "",
        sort_query: "",
        aggregate_by: "",
        by_pass_type: "",
        by_grad_year: [],
        student_ids: [],
        teacher_ids: [],
        room_ids: [],
        by_school_year: [],
        dates:
          moment().format("MM/DD/YYYY") + " - " + moment().format("MM/DD/YYYY")
      },
      errors: ""
    })

    const tabs = tableStaticData.tabs
    const tableDropdowns = tableStaticData.tableDropdowns
    const aggregateByOptions = tableStaticData.aggregateByOptions
    const passTypesOptions = tableStaticData.passTypesOptions
    const dataTableFields = tableStaticData.dataTableFields
    const dataTableFieldsBackgroundReports =
      tableStaticData.dataTableFieldsBackgroundReports

    const school = computed(() => {
      return store.getters["schools/activeSchool"]
    })

    const summaryReports = computed(() => {
      return store.getters["summaryReports/summaryReports"]
    })

    const summaryReportsRequest = computed(() => {
      return store.getters["summaryReports/summaryReportsRequest"]
    })

    const activeDataTableItems = computed(() => {
      return isNowReportsTab.value
        ? summaryReports.value
        : summaryReportsRequest.value
    })

    const activeTableTabs = computed(() => {
      return tabs.filter((tab) =>
        school.value.is_premium == 1 ? tab : tab.value !== "backgroundReports"
      )
    })

    const activeTableFields = computed(() => {
      const fields = dataTableFields.filter((field) =>
        state.showStudentNumber ? field : field.key !== "student_sis_id"
      )
      return isNowReportsTab.value ? fields : dataTableFieldsBackgroundReports
    })

    const activeTableDropdowns = computed(() => {
      return tableDropdowns.column.filter((items) =>
        state.filterParams.aggregate_by == "highest_pass_takers"
          ? items
          : items.value !== "student_id"
      )
    })

    const isNowReportsTab = computed(() => {
      return state.filterParams.activeTab == "nowReport"
    })

    const maxDate = computed(() => {
      if (state.filterParams.by_school_year?.value) {
        return new Date(state.filterParams.by_school_year.value[1], 5, 30)
      }
      return new Date()
    })
    const minDate = computed(() => {
      if (state.filterParams.by_school_year?.value) {
        return new Date(state.filterParams.by_school_year.value[0], 6, 1)
      }
      return new Date()
    })

    const optionalDateRanges = computed(() => {
      if (state.filterParams.by_school_year?.value && minDate.value) {
        return [
          {
            label: "Today",
            id: "",
            range: helpersJS.stringDateRange(moment(), moment())
          },
          {
            label: "Yesterday",
            id: "",
            range: helpersJS.stringDateRange(
              moment().subtract(1, "days"),
              moment().subtract(1, "days")
            )
          },
          {
            label: "Last 7 days",
            id: "",
            range: helpersJS.stringDateRange(
              moment().subtract(7, "days") < moment(minDate.value)
                ? moment(minDate.value)
                : moment().subtract(7, "days"),
              moment()
            )
          },
          {
            label: "Last 14 days",
            id: "",
            range: helpersJS.stringDateRange(
              moment().subtract(14, "days") < moment(minDate.value)
                ? moment(minDate.value)
                : moment().subtract(14, "days"),
              moment()
            )
          },
          {
            label: "Last 30 days",
            id: "",
            range: helpersJS.stringDateRange(
              moment().subtract(30, "days") < moment(minDate.value)
                ? moment(minDate.value)
                : moment().subtract(30, "days"),
              moment()
            )
          },
          {
            label: "This month",
            id: "",
            range: helpersJS.stringDateRange(
              moment().startOf("month"),
              moment().endOf("month")
            )
          },
          {
            label: "This year",
            id: "",
            range:
              helpersJS.currTzDate(minDate.value) +
              " - " +
              helpersJS.currTzDate(maxDate.value)
          },
          {
            label: "Custom range",
            range: null,
            id: "custom"
          }
        ]
      }
      return [
        {
          label: "Today",
          id: "",
          range: helpersJS.stringDateRange(moment(), moment())
        }
      ]
    })

    const gradeYears = computed(() => {
      const gradeYears = []
      const currentYear = new Date().getFullYear()
      const today = moment().format("YYYY-MM-DD HH:mm:ss")
      const newSchoolYear = moment(today).isAfter(
        moment(`${currentYear}-06-30 23:59:59`)
      )
      if (moment(today).year() == currentYear && newSchoolYear) {
        for (let i = 0; i <= currentYear - 2021; i++) {
          gradeYears.push({
            value: [2021 + i, 2022 + i],
            label: `${2021 + i}-${22 + i}`
          })
        }
      } else {
        for (let i = 0; i < currentYear - 2021; i++) {
          gradeYears.push({
            value: [2021 + i, 2022 + i],
            label: `${2021 + i}-${22 + i}`
          })
        }
      }
      return gradeYears
    })

    const currentSchoolYearSelected = computed(() => {
      const currentYearIndex = gradeYears.value.length - 1
      return (
        state.filterParams.by_school_year?.label ==
        gradeYears.value[currentYearIndex].label
      )
    })

    const validationMessages = {
      required: "This field is required"
    }
    const validations = {
      filterParams: {
        aggregate_by: {
          required: helpers.withMessage(validationMessages.required, required)
        }
      }
    }
    const v$ = useVuelidate(validations.filterParams, state.filterParams)

    const isFormValid = computed(() => !v$.value.$invalid)

    const errors = computed(() => {
      const errorObj = {}
      v$.value.$errors.forEach((err) => {
        errorObj[err.$property] = err.$message
      })

      return errorObj
    })

    onMounted(() => {
      getGradYears()
      initLazyLoading()
      handleReports()
      state.filterParams.activeTab = tabs[0].value
      state.filterParams.by_school_year = gradeYears.value
        ? gradeYears.value[gradeYears.value.length - 1]
        : state.filterParams.by_school_year
      state.filterParams.by_pass_type = passTypesOptions[0].value
    })

    const setActiveTab = (tab) => {
      state.filterParams.activeTab = tab.value
      clearFilterParams()
      resetSearchQuery()
      clearPagination()
      v$.value.$reset()
      if (tab.value == "backgroundReports") {
        getSummaryReportsRequestData()
      }
    }

    const getGradYears = () => {
      store.dispatch("passBlocks/getGradeYears").then((result) => {
        state.gradYears = result.data.data
      })
    }

    const toggleAdvancedFiltersPanel = () => {
      state.isAdFiltersPanelVisible = !state.isAdFiltersPanelVisible
    }

    const onColumnOptionsChange = (e) => {
      state.showStudentNumber =
        e &&
        e.length &&
        state.filterParams.aggregate_by == "highest_pass_takers"
          ? true
          : false
    }

    const switchAggregateBy = () => {
      if (state.filterParams.aggregate_by !== "highest_pass_takers") {
        state.showStudentNumber = false
      }
    }

    const toggleSearchByColumn = () => {
      state.filter = !state.filter
    }

    const getSummaryReportsRequestData = (page) => {
      state.isLoading = true
      state.pagination.activePage = page ? page : 1
      const params = {
        per_page: state.pagination.per_page.value,
        page: state.pagination.activePage
      }
      if (state.filterParams.search_query) {
        params.search_query = state.filterParams.search_query
      }
      if (state.filterParams.sort_query) {
        params.sort = state.filterParams.sort_query
      }
      if (state.filterParams.search_id) {
        params.search_id = state.filterParams.search_id
      }
      store
        .dispatch("summaryReports/getSummaryReportsRequestData", params)
        .then((response) => {
          const data = response.data
          helpersJS.scrollToTop()
          if (data) {
            store.commit(
              "summaryReports/SET_SUMMARY_REPORTS_REQUEST",
              data.data
            )
            state.pagination.total = data.meta.total
            state.pagination.from = data.meta.from
            state.pagination.to = data.meta.to
            state.pagination.pages = Math.ceil(
              data.meta.total / data.meta.per_page
            )
            state.pagination.activePage = Number(state.pagination.activePage)
            state.pagination = JSON.parse(JSON.stringify(state.pagination))
            state.isLoading = false
          }
        })
    }

    const setPerPage = (option) => {
      if (option) {
        state.isLazyLoadingMode = option.label === "All entries"
        state.pagination.per_page = option
        if (isNowReportsTab.value) {
          handleReports()
        } else {
          getSummaryReportsRequestData()
        }
      }
    }

    const namesById = (data) => {
      if (data) {
        const names = []
        Object.keys(data).forEach((key) => {
          names.push(data[key])
        })
        return names.join(", ")
      } else {
        return null
      }
    }

    const by_grad_year = (data) => {
      if (data && data.length) {
        return data.join(", ")
      } else {
        return null
      }
    }

    const transformRequestedAt = (data) => {
      if (data) {
        return moment(data).format("MM/DD/YYYY HH:mm:ss")
      } else {
        return null
      }
    }

    const sendReport = () => {
      if (isFormValid.value) {
        resetSearchQuery()
        handleReports(1, false, false, true)
      } else {
        v$.value.$touch()
      }
    }

    const initLazyLoading = () => {
      const body = document.getElementsByClassName("main-scroll-container")[0]
      if (body) {
        body.onscroll = () => {
          if (
            state.isLazyLoadingMode &&
            summaryReports.value &&
            summaryReports.value.length &&
            state.pagination.total > summaryReports.value.length
          ) {
            if (body.offsetHeight + body.scrollTop + 1 >= body.scrollHeight) {
              state.pagination.activePage = state.pagination.activePage + 1
              handleReports(
                state.pagination.activePage,
                false,
                true,
                false,
                false
              )
            }
          }
        }
      }
    }

    const limitToCurrentSchoolYear = () => {
      return state.filterParams.dates
        .split(" - ")
        .map((date) => {
          return helpersJS.isPreviousYearDate(date)
            ? helpersJS.currTzDate(helpersJS.minSchoolYearDate())
            : date
        })
        .join(" - ")
    }

    const handleReports = (
      page,
      isExport,
      isLazyLoadingMode,
      isSendReport,
      noLoader = true
    ) => {
      state.isLoading = !noLoader
      if (state.filterParams.aggregate_by) {
        state.pagination.activePage = page ? page : 1
        const dateRange = currentSchoolYearSelected.value
          ? limitToCurrentSchoolYear()
          : state.filterParams.dates
        const paramsNowReport = {
          aggregate_by: state.filterParams.aggregate_by,
          teacher_ids: state.filterParams.teacher_ids.length
            ? state.filterParams.teacher_ids.map((el) => el.value.id)
            : state.filterParams.teacher_ids,
          student_ids: state.filterParams.student_ids.length
            ? state.filterParams.student_ids.map((el) => el.value.id)
            : state.filterParams.student_ids,
          room_ids: state.filterParams.room_ids.length
            ? state.filterParams.room_ids.map((el) => el.value.id)
            : state.filterParams.room_ids,
          by_pass_types: state.filterParams.by_pass_type,
          by_grad_year: state.filterParams.by_grad_year,
          from_date: dateRange.split(" - ")[0],
          to_date: dateRange.split(" - ")[1],
          per_page: state.pagination.per_page.value,
          page: state.pagination.activePage
        }
        const paramsBackgroundReport = {
          aggregate_by_label:
            state.filterParams.aggregate_by == "highest_pass_destination"
              ? "Locations (pass destination)"
              : state.filterParams.aggregate_by == "highest_pass_granters"
                ? "Adults (pass granters)"
                : "Students (pass takers)",
          aggregate_by_value: state.filterParams.aggregate_by,
          teachers: state.filterParams.teacher_ids.length
            ? state.filterParams.teacher_ids.map((el) => el.value.id)
            : state.filterParams.teacher_ids,
          students: state.filterParams.student_ids.length
            ? state.filterParams.student_ids.map((el) => el.value.id)
            : state.filterParams.student_ids,
          rooms: state.filterParams.room_ids.length
            ? state.filterParams.room_ids.map((el) => el.value.id)
            : state.filterParams.room_ids,
          by_pass_type: state.filterParams.by_pass_type,
          by_grad_years: state.filterParams.by_grad_year,
          by_school_year:
            state.filterParams.by_school_year.value[0] +
            "-" +
            state.filterParams.by_school_year.value[1],
          dates: dateRange,
          per_page: state.pagination.per_page.value,
          page: state.pagination.activePage
        }

        if (state.filterParams.search_query) {
          paramsNowReport.search_query = state.filterParams.search_query
          paramsBackgroundReport.search_query = state.filterParams.search_query
        }
        if (state.filterParams.sort_query) {
          paramsNowReport.sort = state.filterParams.sort_query
          paramsBackgroundReport.search_query = state.filterParams.search_query
        }
        if (state.filterParams.search_id) {
          paramsNowReport.search_id = state.filterParams.search_id
          paramsBackgroundReport.search_query = state.filterParams.search_query
        }
        if (isExport) {
          getCSVexport(paramsNowReport)
        } else {
          isNowReportsTab.value
            ? getSummaryReports(
                paramsNowReport,
                isLazyLoadingMode,
                isSendReport
              )
            : createBackgroundReports(paramsBackgroundReport, isLazyLoadingMode)
        }
      } else {
        store.commit("summaryReports/SET_SUMMARY_REPORTS", [])
        state.isLoading = false
      }
    }

    const getSummaryReports = (data, isLazyLoadingMode, isSendReport) => {
      if (isSendReport) {
        state.isLoading = true
        store
          .dispatch("summaryReports/getSummaryReports", data)
          .then((response) => {
            const responseData = response.data.data
            if (responseData && responseData.search_id) {
              state.filterParams.search_id = responseData.search_id
              const params = { ...data, search_id: responseData.search_id }
              getSummaryReportsResults(params, isLazyLoadingMode)
            }
          })
          .catch(() => {
            state.isLoading = false
            if (state.filterParams.aggregate_by) {
              state.errors =
                "The results of your search are too large to show, please reduce the dates of your search to see results."
            }
          })
      } else {
        getSummaryReportsResults(data, isLazyLoadingMode)
      }
    }

    const getSummaryReportsResults = (params, isLazyLoadingMode) => {
      state.errors = ""
      state.isLoading = true
      store
        .dispatch("summaryReports/getSummaryReportsResults", params)
        .then((response) => {
          const data = response.data
          if (data.data) {
            if (isLazyLoadingMode) {
              store.commit("summaryReports/PUSH_SUMMARY_REPORTS", data.data)
            } else {
              store.commit("summaryReports/SET_SUMMARY_REPORTS", data.data)
            }
          }
          if (data.meta) {
            state.pagination.total = data.meta.total
            state.pagination.from = data.meta.from
            state.pagination.to = data.meta.to
            state.pagination.pages = Math.ceil(
              data.meta.total / data.meta.per_page
            )
            state.pagination.activePage = Number(state.pagination.activePage)
            state.pagination = JSON.parse(JSON.stringify(state.pagination))
          }
          state.isLoading = false
        })
        .catch(() => {
          state.isLoading = false
          if (state.filterParams.aggregate_by) {
            state.errors =
              "The results of your search are too large to show, please reduce the dates of your search to see results."
          }
        })
    }

    const createBackgroundReports = (data) => {
      state.isLoading = true
      store
        .dispatch("summaryReports/createSummaryReportRequest", data)
        .then(() => {
          getSummaryReportsRequestData()
          clearFilterParams()
          v$.value.$reset()
          state.isLoading = false
        })
        .catch((err) => {
          const res = err.response.data
          state.errors = res.message ? res.message : "Something went wrong"
          state.isLoading = false
        })
    }

    const exportCSV = () => {
      handleReports(state.pagination.activePage, true)
    }

    const getCSVexport = (params) => {
      state.isLoading = true
      params.per_page = [25, 50, 100].includes(state.pagination.per_page.value)
        ? state.pagination.per_page.value
        : "all"
      store
        .dispatch("summaryReports/getSummaryReportsResults", {
          ...params,
          export_csv: true,
          student_sis_id: state.showStudentNumber
        })
        .then((response) => {
          if (response.data) {
            download.CSVExport(response.data, "summary_reports")
          }
        })
        .finally(() => {
          state.isLoading = false
        })
    }

    const setActivePage = (page) => {
      state.pagination.activePage = page
      if (isNowReportsTab.value) {
        handleReports(state.pagination.activePage)
      } else {
        getSummaryReportsRequestData(state.pagination.activePage)
      }
      helpersJS.scrollToTop()
    }

    const searchByQuery = (value, type) => {
      clearInterval(state.searchTimeOut)
      state.searchTimeOut = setTimeout(() => {
        handleQuerySearch(value, type)
      }, 500)
    }

    const handleQuerySearch = (value, type) => {
      const query = value.toString()
      if (
        query.replace(/\s/g, "").length > 0 ||
        query.replace(/\s/g, "").length === 0
      ) {
        state.filterParams.search_query = ""
        if (type && type === "column") {
          state.columnSearchKeys.globalQuery = ""
          for (const key in state.columnSearchKeys) {
            if (Object.hasOwnProperty.call(state.columnSearchKeys, key)) {
              const columnData = state.columnSearchKeys[key]
              if (columnData.value) {
                columnData.column.forEach((col) => {
                  state.filterParams.search_query =
                    col === "destination.sortable_name"
                      ? state.filterParams.search_query +
                        `${col}:"${columnData.value}"|destination.name:"${columnData.value}", `
                      : state.filterParams.search_query +
                        `${col}:"${columnData.value}", `
                })
              }
            }
          }
          state.filterParams.search_query =
            state.filterParams.search_query.slice(0, -2)
        } else {
          resetSearchQuery()
          state.columnSearchKeys.globalQuery = query
          state.watchSearchQuery = query
          state.filterParams.search_query = query ? '"' + query + '"' : query
        }
        if (state.filterParams.aggregate_by && isNowReportsTab.value) {
          handleReports()
        } else {
          getSummaryReportsRequestData()
        }
      }
    }

    const sortByColumn = (column) => {
      const columnName = state.columnSearchKeys[column.column]
        ? state.columnSearchKeys[column.column].sortBy
        : column.column
      state.filterParams.sort_query = `${columnName}:${
        column.asc ? "asc" : "desc"
      }`
      if (isNowReportsTab.value) {
        handleReports()
      } else {
        getSummaryReportsRequestData()
      }
    }

    const selectObject = (data, type) => {
      const selectData = []
      Object.keys(data).forEach((key) => {
        selectData.push({
          label: data[key],
          value: {
            id: key,
            type: type == "user" ? "App\\Models\\Users" : "App\\Models\\Room"
          }
        })
      })
      return selectData
    }

    const setFormByRequestRecord = (data) => {
      state.filterParams = Object.assign(state.filterParams, {
        aggregate_by: data.aggregate_by_value,
        by_pass_type: data.by_pass_type,
        by_grad_year: data.by_grad_years ? data.by_grad_years : [],
        teacher_ids: data.teachers ? selectObject(data.teachers, "user") : [],
        student_ids: data.students ? selectObject(data.students, "user") : [],
        room_ids: data.rooms ? selectObject(data.rooms, "room") : [],
        by_school_year:
          gradeYears.value && data.by_school_year
            ? {
                label:
                  data.by_school_year.slice(0, 4) +
                  "-" +
                  data.by_school_year.slice(7),
                value: [
                  Number(data.by_school_year.slice(0, 4)),
                  Number(data.by_school_year.slice(5))
                ]
              }
            : gradeYears.value[gradeYears.value.length - 1],
        dates: data.dates
          ? data.dates
          : helpersJS.stringDateRange(moment(), moment())
      })
    }

    const setCalendarGradeYears = () => {
      state.filterParams.dates = `${helpersJS.currTzDate(minDate.value)} - ${helpersJS.currTzDate(maxDate.value)}`
    }

    const getReportActions = (report) => {
      if (report.status == "PROCESSED") {
        return state.reportRequestAction
      }
      if (report.status == "FAILED" || report.status == "EXPIRED") {
        return state.reportRequestAction.filter(
          (action) => action.action !== "download"
        )
      }
      if (report.status == "PROCESSING") {
        return state.reportRequestAction.filter(
          (action) => action.action == "duplicate"
        )
      }
    }

    const handleTableAction = (event, item) => {
      if (event.action == "rerun") {
        rerunReportRequest(item)
      }
      if (event.action == "download") {
        downloadReportRequest(item)
      }
      if (event.action == "duplicate") {
        duplicateReportRequest(item)
      }
      if (event.action == "delete") {
        actionDialog.open(deleteReportRequest, {
          args: item,
          props: { danger: true, title: "Delete", question: "Are you sure?" }
        })
      }
    }

    const rerunReportRequest = (report) => {
      store
        .dispatch("summaryReports/rerunSummaryReportRequest", report.id)
        .then(() => {
          getSummaryReportsRequestData()
        })
        .catch((err) => {
          modal.open(InfoBox, {
            size: "md",
            title: "Something went wrong!",
            props: {
              message: err.response.data.message
            }
          })
        })
    }

    const downloadReportRequest = (report) => {
      state.isLoading = true
      store
        .dispatch("summaryReports/downloadSummaryReportRequest", report.id)
        .then((response) => {
          if (response.data) {
            download.CSVExport(response.data, `Report request ${report.dates}`)
          }
          state.isLoading = false
        })
        .catch((err) => {
          state.isLoading = false
          modal.open(InfoBox, {
            size: "md",
            title: "Something went wrong!",
            props: {
              message: err.response.data.message
            }
          })
        })
    }

    const duplicateReportRequest = (report) => {
      store
        .dispatch("summaryReports/getSummaryReportRequestData", report.id)
        .then((response) => {
          const data = response.data.data
          setFormByRequestRecord(data)
          helpersJS.scrollToTop()
        })
    }

    const deleteReportRequest = (report) => {
      store
        .dispatch("summaryReports/deleteSummaryReportRequest", report.id)
        .then(() => {
          getSummaryReportsRequestData()
        })
        .catch((err) => {
          modal.open(InfoBox, {
            size: "md",
            title: "Something went wrong!",
            props: {
              message: err.response.data.message
            }
          })
        })
    }

    const clearFilter = () => {
      clearFilterParams()
      searchByQuery("")
    }

    const clearPagination = () => {
      state.pagination = {
        activePage: 1,
        total: 0,
        pages: 0,
        per_page: { label: "25", value: 25 }
      }
    }

    const clearFilterParams = () => {
      state.filterParams = Object.assign(state.filterParams, {
        search_id: "",
        search_query: "",
        sort_query: "",
        aggregate_by: "",
        by_pass_type: passTypesOptions[0].value,
        by_grad_year: [],
        student_ids: [],
        teacher_ids: [],
        room_ids: [],
        by_school_year: gradeYears.value
          ? gradeYears.value[gradeYears.value.length - 1]
          : state.filterParams.by_school_year,
        dates:
          moment().format("MM/DD/YYYY") + " - " + moment().format("MM/DD/YYYY")
      })
      state.errors = ""
      state.showStudentNumber = false
    }

    const resetSearchQuery = () => {
      state.filterParams.search_query = ""
      state.columnSearchKeys = {
        globalQuery: "",
        // NOW REPORTS tab
        student_sis_id: {
          value: "",
          column: ["student_sis_id"],
          sortBy: "student_sis_id"
        },
        destination: {
          value: "",
          column: ["destination.sortable_name"],
          sortBy: "destination.sortable_name"
        },
        total_passes: {
          value: "",
          column: ["total_passes"],
          sortBy: "total_passes.raw_int"
        },
        total_time_all: {
          value: "",
          column: ["total_time_all"],
          sortBy: "total_time_all"
        },
        total_time_student: {
          value: "",
          column: ["total_time_student"],
          sortBy: "total_time_student"
        },
        total_time_aup: {
          value: "",
          column: ["total_time_aup"],
          sortBy: "total_time_aup"
        },
        total_time_proxy: {
          value: "",
          column: ["total_time_proxy"],
          sortBy: "total_time_proxy"
        },
        total_time_apt: {
          value: "",
          column: ["total_time_apt"],
          sortBy: "total_time_apt"
        },
        total_time_ksk: {
          value: "",
          column: ["total_time_ksk"],
          sortBy: "total_time_ksk"
        },
        // BACKGROUND REPORTS tab
        aggregate_by_label: {
          value: "",
          column: ["aggregate_by_label"],
          sortBy: "aggregate_by_label"
        },
        teachers: {
          value: "",
          column: ["teachers"],
          sortBy: "teachers"
        },
        students: {
          value: "",
          column: ["students"],
          sortBy: "students"
        },
        rooms: {
          value: "",
          column: ["rooms"],
          sortBy: "rooms"
        },
        by_pass_type: {
          value: "",
          column: ["by_pass_type"],
          sortBy: "by_pass_type"
        },
        by_grad_years: {
          value: "",
          column: ["by_grad_years"],
          sortBy: "by_grad_years"
        },
        by_school_year: {
          value: "",
          column: ["by_school_year"],
          sortBy: "by_school_year"
        },
        dates: {
          value: "",
          column: ["dates"],
          sortBy: "dates"
        },
        requested_at: {
          value: "",
          column: ["requested_at"],
          sortBy: "requested_at"
        },
        runned_at: {
          value: "",
          column: ["runned_at"],
          sortBy: "runned_at"
        },
        requested_by: {
          value: "",
          column: ["requested_by"],
          sortBy: "requested_by"
        },
        runned_by: {
          value: "",
          column: ["runned_by"],
          sortBy: "runned_by"
        },
        status: {
          value: "",
          column: ["status"],
          sortBy: "status"
        },
        action: {
          filter: false,
          sorter: false,
          value: "",
          column: ["action"],
          sortBy: "action"
        }
      }
    }

    return {
      helpersJS,
      state,
      tabs,
      aggregateByOptions,
      passTypesOptions,
      dataTableFields,
      activeTableDropdowns,
      school,
      summaryReports,
      activeDataTableItems,
      isNowReportsTab,
      minDate,
      maxDate,
      optionalDateRanges,
      activeTableTabs,
      activeTableFields,
      gradeYears,
      currentSchoolYearSelected,
      v$,
      isFormValid,
      errors,
      setActiveTab,
      toggleAdvancedFiltersPanel,
      onColumnOptionsChange,
      switchAggregateBy,
      toggleSearchByColumn,
      setCalendarGradeYears,
      setPerPage,
      namesById,
      by_grad_year,
      transformRequestedAt,
      sendReport,
      setActivePage,
      handleReports,
      exportCSV,
      searchByQuery,
      sortByColumn,
      getReportActions,
      handleTableAction,
      clearFilter
    }
  }
}
</script>
<style lang="scss" scoped>
.select-width-350 {
  width: 350px;
}
</style>
