<template>
  <CModal
    class="modal-v3"
    backdrop="static"
    :close-on-backdrop="false"
    v-bind="{ visible, size, scrollable }"
    @close="closeModal"
  >
    <!-- Header -->
    <div
      v-if="!hideHeader"
      class="modal-v3-header create-popup-header d-flex align-items-center justify-content-between"
    >
      <span v-if="help"> <HelpCenterButton :content-key="help" /></span>
      <div class="modal-v3-header-text flex-1 text-center">
        <div v-if="title">
          {{ title }}
        </div>
        <slot name="header"> </slot>
      </div>
      <div v-if="!hideCloseButton" class="text-end close-btn">
        <i class="ri-close-line cursor-pointer" @click="closeModal" />
      </div>
    </div>
    <!-- Body -->
    <div class="modal-v3-body">
      <component
        @cancel="closeModal"
        v-if="modal && modal.component"
        :is="modal.component"
        v-bind="modal.options.props"
        v-on="modal.options.props.events"
      ></component>
      <slot></slot>
    </div>
    <!-- Footer -->
    <div class="modal-v3-footer">
      <slot name="footer"> </slot>
    </div>
  </CModal>
</template>

<script>
import { inject } from "vue"
import { onBeforeRouteUpdate } from "vue-router"
import HelpCenterButton from "@/v3components/HelpCenterButton.vue"

export default {
  components: { HelpCenterButton },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: "sm"
    },
    hideHeader: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    },
    help: {
      type: String,
      default: ""
    },
    scrollable: {
      type: Boolean,
      default: false
    },
    hideCloseButton: {
      type: Boolean,
      default: false
    }
  },
  emits: ["close"],
  setup(props, { emit }) {
    const closeModal = () => {
      emit("close")
    }
    onBeforeRouteUpdate(() => {
      closeModal()
    })
    const modal = inject("modal")

    return {
      closeModal,
      modal
    }
  }
}
</script>
