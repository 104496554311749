<template>
  <VisitorWatchlistOffenderDeniedColumn
    v-if="isWatchlistOrOffenderDenied"
    :visitor="visitor"
    :is-badge-printing-failed="isBadgePrintingFailed"
    :status-tooltip="statusTooltip"
  />
  <div
    v-else
    class="d-flex flex-wrap gap-1"
    :class="statusClasses"
    @click.stop="onVisitorStatusClick"
  >
    <div
      class="d-flex flex-wrap justify-content-center align-content-center gap-2 visitor-status-pill"
    >
      <i class="visitor-status-icon" :class="iconStyleClasses"></i>
      <span class="mt-3px text-capitalize">
        {{ visitor.visit_status }}
      </span>
    </div>
    <i
      v-if="statusTooltip.length > 0"
      :title="statusTooltip"
      class="m-auto"
      :class="{
        'ri-information-line': !isBadgePrintingFailed,
        'ri-printer-line visitor-status-red': isBadgePrintingFailed
      }"
    ></i>
  </div>
</template>

<script>
import VisitorWatchlistOffenderDeniedColumn from "./VisitorWatchlistOffenderDeniedColumn.vue"
import { computed } from "vue"

export default {
  name: "VisitorStatusColumn",
  components: {
    VisitorWatchlistOffenderDeniedColumn
  },
  props: {
    visitor: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    const statusClasses = computed(() => {
      const classMap = {
        denied: "visitor-status-red",
        "signed in": "visitor-status-green",
        "signed out": "visitor-status-blue"
      }
      const className =
        classMap?.[props?.visitor?.visit_status?.toLowerCase()] ||
        "visitor-status-default"
      return className
    })

    const iconStyleClasses = computed(() => {
      const visitStatus = props?.visitor?.visit_status?.toLowerCase()
      return {
        "signed-in ri-check-line": visitStatus === "signed in",
        "signed-out ri-check-line": visitStatus === "signed out",
        "denied ri-close-line": visitStatus === "denied"
      }
    })

    const isBadgePrintingFailed = computed(() => {
      return (
        props?.visitor?.signin_exception_message
          ?.toString()
          ?.trim()
          ?.toLowerCase() === "app_badge_printing_failed"
      )
    })

    const isWatchlistOrOffenderDenied = computed(() => {
      const visitStatus =
        props?.visitor?.visit_status?.trim()?.toLowerCase() || ""
      const message =
        props?.visitor?.signin_exception_message
          ?.toString()
          ?.trim()
          ?.toLowerCase() || ""

      return (
        visitStatus === "denied" &&
        (message?.includes("watchlist database match") ||
          message?.includes("offender database match"))
      )
    })

    const statusTooltip = computed(() => {
      if (isBadgePrintingFailed.value) {
        return "Badge printing failed"
      }

      let description, matchType
      switch (props?.visitor?.match_type?.trim()?.toLowerCase()) {
        case "offender":
          matchType = "Offender"
          break
        case "watchlist":
          matchType = "Watchlist"
          break
        case "pre-approved-visitor":
          matchType = "Pre-set visitor"
          break
        default:
          matchType = "Visitor"
      }
      if (props?.visitor?.visit_status?.trim()?.toLowerCase() === "denied") {
        description = "Denial reason:"
      } else if (props?.visitor?.health_check === "Fail") {
        description = "Custom question exception reason:"
      } else {
        description = `${matchType} exception reason:`
      }

      const message = props?.visitor?.signin_exception_message || ""

      if (message?.length) {
        return `${description} ${message}`
      }

      return ""
    })

    return {
      isWatchlistOrOffenderDenied,
      iconStyleClasses,
      statusClasses,
      statusTooltip,
      isBadgePrintingFailed
    }
  }
}
</script>

<style scoped>
.mt-3px {
  margin-top: 3px;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
