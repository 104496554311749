<template>
  <div class="v3-data-table">
    <table v-if="state.reOrderedPeriods" class="table bg-white">
      <thead>
        <tr>
          <th class="period-table-th position-relative" />
          <th class="period-table-th position-relative"><div>Name</div></th>
          <th class="period-table-th position-relative">
            <div>Status</div>
          </th>
          <th class="period-table-th position-relative" style="max-width: 50px">
            <div class="text-end">Action</div>
          </th>
        </tr>
      </thead>
      <draggable
        v-model="state.reOrderedPeriods"
        item-key="id"
        filter=".no-drag"
        tag="tbody"
        @end="saveNewPeriodsOrder"
      >
        <template #item="{ element, index }">
          <tr class="w-100 bg-white">
            <td style="width: 100px" class="mw-auto text-center">
              <i class="ri-drag-move-2-line text-value-xl"></i>
            </td>
            <td>{{ element.name }}</td>
            <td>
              <LabelPill
                :class="`status-${element.status ? 'active' : 'inactive'}`"
                :icon="
                  element.status
                    ? 'ri-checkbox-circle-fill'
                    : 'ri-close-circle-fill'
                "
              >
                {{ element.status ? "Active" : "Inactive" }}
              </LabelPill>
            </td>
            <td style="width: 50px">
              <div class="text-end">
                <Actions
                  :actions="tableActions"
                  @click-action="handleTableAction($event, element, index + 1)"
                ></Actions>
              </div>
            </td>
          </tr>
        </template>
      </draggable>
    </table>
  </div>
</template>

<script>
import { onMounted, computed, reactive, watch, inject } from "vue"
import draggable from "vuedraggable"
import Actions from "@/v3components/shared/DataTable/Actions.vue"
import CreatePeriodForm from "../Forms/CreatePeriodForm.vue"
import LabelPill from "../shared/Buttons/LabelPill.vue"
import { useStore } from "vuex"
export default {
  components: {
    draggable,
    Actions,
    LabelPill
  },
  setup() {
    const store = useStore()
    const state = reactive({
      reOrderedPeriods: []
    })

    const actionDialog = inject("actionDialog")
    const modal = inject("modal")

    const periods = computed(() => store.getters["periods/periods"])

    const onDeletePeriod = (item) => {
      actionDialog.open(deletePeriod, {
        args: item,
        props: { danger: true, title: "Delete", question: "Are you sure?" }
      })
    }

    const onEditPeriod = (item) => {
      store.commit("periods/SET_EDITABLE_PERIOD", item)
      showPeriodForm(item)
    }

    const showPeriodForm = () => {
      modal.open(CreatePeriodForm, {
        size: "md",
        title: "Edit period",
        props: {
          editableMode: true
        }
      })
    }

    const deletePeriod = (period) => {
      store
        .dispatch("periods/deletePeriod", { periodId: period.id })
        .then(() => {
          setTimeout(() => {
            store.dispatch("periods/getPeriods", {
              clearCacheEntry: true
            })
          }, 750)
        })
    }

    const saveNewPeriodsOrder = () => {
      const data = state.reOrderedPeriods.map((period, i) => {
        return {
          id: period.id,
          position: i + 1
        }
      })
      store.dispatch("periods/updatePosition", data)
    }

    watch(
      () => periods.value,
      () => {
        state.reOrderedPeriods = periods.value
      }
    )

    const tableActions = [
      { label: "Edit", icon: "ri-checkbox-circle-line", action: "edit" },
      {
        label: "Delete",
        icon: "ri-delete-bin-line",
        class: "important",
        action: "delete"
      }
    ]
    const handleTableAction = (action, item) => {
      if (action.action === "delete") {
        onDeletePeriod(item)
      } else {
        onEditPeriod(item)
      }
    }

    onMounted(() => {
      store.dispatch("periods/getPeriods")
    })

    return {
      periods,
      saveNewPeriodsOrder,
      state,
      tableActions,
      handleTableAction
    }
  }
}
</script>

<style lang="scss" scoped>
.v3-data-table {
  padding: 0 8px !important;
  table {
    thead {
      tr:first-child {
        th:first-child {
          &::after {
            border-right: 0 !important;
          }
        }
      }
    }
  }
}
</style>
