<template>
  <div>
    <CAlert
      v-if="state.reqResponse.message"
      class="mt-2 mb-4"
      :color="state.reqResponse.type"
      :show="!!state.reqResponse.message"
    >
      {{ state.reqResponse.message }}
    </CAlert>

    <div class="form-group">
      <VSelect
        v-model="state.form.role_id"
        class="cs-select mb-2 ps__child--consume"
        :options="state.formData.roles"
        placeholder="Role"
        :clearable="false"
        :reduce="(item) => item.value"
        label="label"
        :class="{
          'is-invalid': errors.role_id
        }"
      >
        <template #footer>
          <div v-if="errors.role_id" class="invalid-feedback">
            {{ errors.role_id }}
          </div>
        </template>
      </VSelect>
    </div>
    <div class="form-group">
      <div class="form-group">
        <input
          v-model="state.form.first_name"
          autocomplete="off"
          type="text"
          class="form-control"
          :class="{
            'is-invalid': errors.first_name
          }"
          placeholder="First Name"
        />
        <div v-if="errors.first_name" class="invalid-feedback">
          {{ errors.first_name }}
        </div>
      </div>
    </div>
    <div class="form-group">
      <div class="form-group">
        <input
          v-model="state.form.last_name"
          autocomplete="off"
          type="text"
          class="form-control"
          :class="{
            'is-invalid': errors.last_name
          }"
          placeholder="Last Name"
        />
        <div v-if="errors.last_name" class="invalid-feedback">
          {{ errors.last_name }}
        </div>
      </div>
    </div>
    <div class="form-group">
      <div class="form-group">
        <input
          v-model="state.form.email"
          autocomplete="off"
          type="text"
          class="form-control"
          :class="{
            'is-invalid': errors.email
          }"
          placeholder="Email"
        />
        <div v-if="errors.email" class="invalid-feedback">
          {{ errors.email }}
        </div>
      </div>
    </div>
    <div v-if="state.form.role_id === 1" class="form-group">
      <div class="form-group">
        <input
          v-model="state.form.grade_year"
          autocomplete="off"
          type="text"
          class="form-control"
          :class="{ 'is-invalid': errors.grade_year }"
          placeholder="Grad Year"
        />
        <div v-if="errors.grade_year" class="invalid-feedback">
          {{ errors.grade_year }}
        </div>
      </div>
    </div>
    <div v-if="state.form.role_id === 1" class="form-group">
      <div class="form-group">
        <input
          v-model="state.form.student_sis_id"
          autocomplete="off"
          type="text"
          class="form-control"
          :class="{ 'is-invalid': errors.student_sis_id }"
          placeholder="Student Number"
        />
        <div v-if="errors.student_sis_id" class="invalid-feedback">
          {{ errors.student_sis_id }}
        </div>
      </div>
    </div>
    <div class="form-group">
      <VSelect
        v-model="state.form.status"
        :class="{
          'is-invalid': errors.status
        }"
        class="cs-select mb-2 ps__child--consume"
        :options="state.formData.status"
        placeholder="Status"
        :clearable="false"
        :reduce="(item) => item.value"
        label="label"
      >
        <template #footer>
          <div v-if="errors.status" class="invalid-feedback">
            {{ errors.status }}
          </div>
        </template>
      </VSelect>
    </div>
    <div v-if="state.form.auth_type === 102" class="form-group">
      <div class="form-group">
        <input
          v-model="state.form.clever_id"
          autocomplete="off"
          type="text"
          class="form-control"
          :class="{ 'is-invalid': errors.clever_id }"
          placeholder="Clever User Id"
        />
        <div v-if="errors.clever_id" class="invalid-feedback">
          {{ errors.clever_id }}
        </div>
      </div>
    </div>

    <div class="form-group">
      <div class="form-group">
        <input
          v-model="state.form.password"
          autocomplete="new-password"
          type="password"
          class="form-control"
          :class="{
            'is-invalid':
              errors.password || state.reqResponse.errors['password']
          }"
          placeholder="Password"
        />
        <div v-if="errors.password" class="invalid-feedback">
          {{ errors.password }}
        </div>
        <div
          v-if="state.reqResponse.errors['password']"
          class="invalid-feedback"
        >
          Password complexity is too weak. Make sure your password has at least
          8 characters, contains a mix of upper and lower case letters, numbers
          and at least one special character. Please avoid common words as a
          part of your password.
        </div>
      </div>
    </div>
    <div class="form-group">
      <div class="form-group">
        <input
          v-model="state.form.password_confirm"
          autocomplete="new-password-confirm"
          type="password"
          class="form-control mt-3"
          :class="{
            'is-invalid':
              errors.password_confirm || state.reqResponse.errors['password']
          }"
          placeholder="Confirm Password"
        />
        <div v-if="errors.password_confirm" class="invalid-feedback">
          {{ errors.password_confirm }}
        </div>
      </div>
    </div>

    <div
      v-if="isActiveKioskModule && state.form.role_id === 1"
      class="form-group"
    >
      <div class="form-group">
        <input
          v-model="state.form.kiosk_passcode"
          autocomplete="new-password"
          type="password"
          class="form-control"
          :class="{ 'is-invalid': errors.kiosk_passcode }"
          placeholder="Kiosk Passcode"
        />
        <div v-if="errors.kiosk_passcode" class="invalid-feedback">
          {{ errors.kiosk_passcode }}
        </div>
      </div>
    </div>

    <div class="form-inline mb-1">
      <CCheckBox
        v-model="state.form.send_invite"
        :checked="!!state.form.send_invite"
        :custom="true"
        label="Send Invite"
        class="custom-checkbox d-flex"
      />
      <HelpCenterButton content-key="login_type_add_edit_info" :is-old="true" />
    </div>
    <div class="form-inline">
      <CCheckBox
        :checked="!!state.form.prevent_archive"
        :class="{
          'is-invalid': errors.prevent_archive
        }"
        label="Prevent Archive"
        v-model="state.form.prevent_archive"
        name="Prevent Archive"
        :custom="true"
        class="custom-checkbox"
      />
      <div v-if="errors.prevent_archive" class="invalid-feedback d-block">
        {{ errors.prevent_archive }}
      </div>
    </div>

    <div v-if="isEditMode" class="d-flex justify-content-center mb-2">
      <div @click="submit()">
        <CLoadingButton
          :spinner="state.isLoading"
          :disabled="state.isLoading"
          :loading="state.isLoading"
          class="me-2 btn bg-gradient-blue text-white"
        >
          Update
        </CLoadingButton>
      </div>
      <div class="ms-2" @click="cancelEdit()">
        <CButton class="me-2 btn-gradient-border">
          <span class="btn px-4"><b class="text-graident-blue">Cancel</b></span>
        </CButton>
      </div>
    </div>
    <div
      v-else
      class="text-center d-flex align-items-center justify-content-center"
    >
      <div @click="submit(true)">
        <CLoadingButton
          :spinner="state.isLoading"
          :loading="state.isLoading"
          class="me-2 mb-2 mt-1 btn bg-gradient-blue reverse text-white"
        >
          Submit
        </CLoadingButton>
      </div>
      <HelpCenterButton
        width="18"
        height="18"
        classes="me-2"
        content-key="form_add_user"
        :is-old="true"
      />
    </div>
  </div>
</template>

<script>
import { reactive, computed, onMounted, onUnmounted, watch } from "vue"
import { useStore } from "vuex"
import {
  helpers,
  required,
  minLength,
  maxLength,
  sameAs
} from "@vuelidate/validators"
import useVuelidate from "@vuelidate/core"
import HelpCenterButton from "@/v3components/HelpCenterButton.vue"
import { passwordStrength } from "check-password-strength"

export default {
  name: "CreateUserForm",
  components: { HelpCenterButton },
  emits: ["userCreated", "userUpdated"],
  setup(props, { emit }) {
    const store = useStore()

    const state = reactive({
      isMounted: false,
      isLoading: false,
      form: {
        role_id: null,
        first_name: "",
        last_name: "",
        email: "",
        status: 1,
        student_sis_id: null,
        prevent_archive: true,
        auth_type: null,
        clever_id: null,
        send_invite: false,
        password: null,
        password_confirm: null,
        kiosk_passcode: null,
        edit: null
      },
      formData: {
        roles: [
          {
            value: 2,
            label: "Admin"
          },
          {
            value: 3,
            label: "Teacher"
          },
          {
            value: 4,
            label: "Staff"
          },
          {
            value: 1,
            label: "Student"
          }
        ],
        status: [
          {
            value: 1,
            label: "Active"
          },
          {
            value: 0,
            label: "Archive"
          }
        ],
        loginType: [
          {
            value: 1,
            label: "Manual"
          },
          {
            value: 10,
            label: "Google"
          },
          {
            value: 20,
            label: "Microsoft"
          }
        ]
      },
      reqResponse: {
        type: "",
        message: "",
        errors: {}
      }
    })

    const editableUser = computed(() => store.getters["users/editableUser"])

    const isActiveKioskModule = computed(
      () => store.getters["schools/isActiveKioskModule"]
    )

    const isEditMode = computed(() => {
      return editableUser.value && editableUser.value.id
    })

    const validationMessages = {
      required: "This field is required",
      minLength: "Password length must contain a minimum of 8 characters.",
      maxLength: "Password length cannot contain more than 64 characters.",
      specialCases: "Password includes characters we do not accept.",
      complexity:
        "Password complexity is too weak. Make sure your password has at least 8 characters, contains a mix of upper and lower case letters, numbers and at least one special character. Please avoid common words as a part of your password.",
      confirmed: "The two passwords do not match."
    }

    const validations = {
      form: {
        role_id: {
          required: helpers.withMessage(validationMessages.required, required)
        },
        first_name: {
          required: helpers.withMessage(validationMessages.required, required)
        },
        last_name: {
          required: helpers.withMessage(validationMessages.required, required)
        },
        email: {
          required: helpers.withMessage(validationMessages.required, required)
        },
        status: {
          required: helpers.withMessage(validationMessages.required, required)
        },
        student_sis_id: {},
        prevent_archive: {},
        clever_id: {},
        auth_type: {},
        password: {
          minLength: helpers.withMessage(
            validationMessages.minLength,
            minLength(8)
          ),
          maxLength: helpers.withMessage(
            validationMessages.maxLength,
            maxLength(64)
          ),
          specialCases: helpers.withMessage(
            validationMessages.specialCases,
            (value) => {
              // eslint-disable-next-line no-control-regex
              const re = new RegExp(/^[\x00-\x7F]+$/g)
              return value ? (value.match(re) ? true : false) : true
            }
          ),
          complexity: helpers.withMessage(
            validationMessages.complexity,
            (value) => {
              return value
                ? passwordStrength(value).id >= 2
                  ? true
                  : false
                : true
            }
          )
        },
        password_confirm: {
          confirmed: helpers.withMessage(
            validationMessages.confirmed,
            sameAs(computed(() => state.form.password))
          )
        },
        send_invite: {}
      }
    }
    const v$ = useVuelidate(validations.form, state.form)

    const isFormValid = computed(() => !v$.value.$invalid)

    const errors = computed(() => {
      const errorObj = {}
      v$.value.$errors.forEach((err) => {
        errorObj[err.$property] = err.$message
      })

      return errorObj
    })

    onMounted(() => {
      state.isMounted = true
    })

    onUnmounted(() => {
      cancelEdit()
    })

    watch(
      () => editableUser.value,
      (value) => {
        resetResponseMessages()
        setFormByUserRecord(value)
      }
    )

    const submit = (isCreate) => {
      resetResponseMessages()
      if (state.form.password == "" || state.form.password_confirm == "") {
        state.form.password = null
        state.form.password_confirm = null
      }
      if (isFormValid.value) {
        if (!isCreate) {
          updateUser(editableUser.value.id)
        } else {
          createUser()
        }
      } else {
        v$.value.$touch()
      }
    }

    const createUser = () => {
      state.isLoading = true
      const form = Object.fromEntries(
        // eslint-disable-next-line
        Object.entries(state.form).filter(([_, v]) => v != null)
      )
      store
        .dispatch("users/createUser", form)
        .then(() => {
          state.isLoading = false
          setSuccessResponse("Successfully created.")
          resetForm()
          setTimeout(() => {
            emit("userCreated")
            cancelEdit()
            resetResponseMessages()
          }, 2500)
        })
        .catch((err) => {
          state.isLoading = false
          const response = err.response.data
          setErrorResponse(response.message, response.errors)
        })
    }

    const updateUser = (userId) => {
      state.isLoading = true
      const data = state.form
      if (data.role_id != 1) {
        data.grade_year = null
      }
      store
        .dispatch("users/updateUser", { userId, data })
        .then(() => {
          state.isLoading = false
          resetForm()
          setSuccessResponse("Successfully updated.")
          setTimeout(() => {
            resetResponseMessages()
            cancelEdit()
            emit("userUpdated")
            store.commit("search/SET_CLEAR_CACHE", true)
          }, 2500)
        })
        .catch((err) => {
          state.isLoading = false
          const response = err.response.data
          setErrorResponse(response.message, response.errors)
        })
    }

    const setFormByUserRecord = (user) => {
      if (user) {
        state.form.role_id = user.current_role_in_school.id
        state.form.first_name = user.first_name
        state.form.last_name = user.last_name
        state.form.email = user.email
        state.form.status = user.status
        state.form.auth_type = user.auth_type
        state.form.prevent_archive =
          !!user.current_role_in_school.pivot.prevent_archive
        state.form.clever_id = user.clever_id
        state.form.grade_year = user.grade_year
        state.form.send_invite = false
        if (user.student_sis_id && user.student_sis_id != "0") {
          state.form.student_sis_id = user.student_sis_id
        }
      }
    }

    const cancelEdit = () => {
      store.commit("users/SET_EDITABLE_USER", null)
      resetForm()
      resetResponseMessages()
    }

    const resetResponseMessages = () => {
      state.reqResponse = {
        type: "",
        message: "",
        errors: {}
      }
    }

    const resetForm = () => {
      if (state.isMounted) {
        v$.value.$reset()
      }
      state.form = Object.assign(state.form, {
        role_id: null,
        first_name: "",
        last_name: "",
        email: "",
        status: 1,
        student_sis_id: null,
        prevent_archive: true,
        auth_type: null,
        clever_id: null,
        send_invite: false,
        password: null,
        password_confirm: null,
        kiosk_passcode: null,
        edit: null
      })
    }

    const setSuccessResponse = (message) => {
      state.reqResponse = {
        type: "success",
        message: message ? message : "Success!",
        errors: {}
      }
    }

    const setErrorResponse = (message, errors) => {
      state.reqResponse = {
        type: "danger",
        message: message ? message : "Someting went wrong!",
        errors: errors ? errors : {}
      }
    }

    return {
      state,
      v$,
      isActiveKioskModule,
      isEditMode,
      isFormValid,
      errors,
      submit,
      resetForm,
      cancelEdit
    }
  }
}
</script>

<style>
.margin-auto {
  margin-left: auto;
}
</style>
