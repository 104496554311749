<template>
  <div
    class="c-app"
    :class="{ 'module-appointmentpass': isActiveAppToLocations }"
  >
    <TheSidebar tabindex="-1" />
    <TheAside tabindex="-1" />
    <div class="wrapper">
      <TheHeader />
      <div class="c-body">
        <CustomScrollbar
          :swicher="state.scrollbarSettings.swicher"
          :settings="state.scrollbarSettings"
          :scroll-component-class="['main-scroll-container']"
          @ps-scroll-y="handleScroll"
        >
          <main
            class="c-main"
            :class="[viewClass, { 'pb-6': devicePlatform === 'iOS' }]"
            tabindex="1"
          >
            <CContainer fluid class="ps-0 pe-0">
              <router-view v-slot="{ Component }">
                <transition name="fade">
                  <component :is="Component" />
                </transition>
              </router-view>
            </CContainer>
          </main>
        </CustomScrollbar>
        <TheFooter />
      </div>
      <CustomModal v-if="modal" v-bind="modal.options" @close="closeModal">
      </CustomModal>
      <ActionDialog
        v-if="actionDialog"
        @close="closeActionDialog"
        @confirm="onDialogConfirm"
        v-bind="actionDialog.options ? actionDialog.options.props : {}"
        :is-visible="actionDialog.isVisible"
      ></ActionDialog>
    </div>
  </div>
</template>

<script>
import { reactive, inject, computed, watch, onMounted } from "vue"
import { useStore } from "vuex"
import { useRoute } from "vue-router"
import TheSidebar from "@/v3components/Sidebar/TheSidebar.vue"
import TheHeader from "@/v3components/Header/TheHeader.vue"
import ActionDialog from "@/v3components/shared/Alerts/ActionDialog.vue"
import TheAside from "@/v3components/Sidebar/TheAside.vue"
import TheFooter from "@/v3components/TheFooter.vue"
import helpers from "@/helpers/index"

export default {
  name: "SideBarLayout",
  components: {
    TheSidebar,
    TheHeader,
    ActionDialog,
    TheAside,
    TheFooter
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const modal = inject("modal")
    const actionDialog = inject("actionDialog")

    const state = reactive({
      scrollbarSettings: {
        suppressScrollY: false,
        suppressScrollX: true,
        swicher: true
      }
    })

    const isActiveAppoinmentModule = computed(
      () => store.getters["schools/isActiveAppoinmentModule"]
    )

    const activeAppoinmentModuleOptions = computed(
      () => store.getters["schools/activeAppoinmentModuleOptions"]
    )

    const viewClass = computed(() => {
      return route.meta.classes ? route.meta.classes : ""
    })

    const isActiveAppToLocations = computed(() => {
      return (
        isActiveAppoinmentModule.value &&
        activeAppoinmentModuleOptions.value &&
        activeAppoinmentModuleOptions.value.location
      )
    })

    const devicePlatform = computed(() => {
      const ua = navigator.userAgent
      if (/android/i.test(ua)) {
        return "Android"
      } else if (
        /iPad|iPhone|iPod/.test(ua) ||
        (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
      ) {
        return "iOS"
      }
      return "Other"
    })

    watch(
      () => route.path,
      () => {
        helpers.scrollToTop()
      }
    )

    onMounted(() => {
      const deviceWidth =
        window.innerWidth > 0 ? window.innerWidth : screen.width
      state.scrollbarSettings.swicher = !deviceWidth || deviceWidth > 700
    })

    const handleScroll = () => {
      const el = document.getElementById("scroller")
      if (el && el.__vue__) {
        el.__vue__.updateVisibleItems()
      }
    }

    const closeModal = () => {
      modal.close()
    }

    const closeActionDialog = () => {
      actionDialog.close()
    }
    const onDialogConfirm = () => {
      actionDialog.confirm()
    }

    return {
      modal,
      actionDialog,
      state,
      viewClass,
      isActiveAppToLocations,
      devicePlatform,
      handleScroll,
      closeModal,
      closeActionDialog,
      onDialogConfirm
    }
  }
}
</script>

<style scoped>
.scrollTopFix {
  overflow: auto !important;
  width: calc(100% + 11px) !important;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
