import axios from "axios"

const state = {
  passReports: [],
  isReportNew: false,
  pagination: {
    activePage: 1,
    total: 0,
    pages: 0,
    per_page: 10 // Number of items per page
  }
}

const getters = {
  passReports(state) {
    return state.passReports
  },
  isReportNew(state) {
    return state.isReportNew
  }
}

const mutations = {
  SET_PASS_REPORTS(state, passReportsCollection) {
    state.passReports = passReportsCollection.data
  },
  PUSH_PASS_REPORTS(state, reportsCollection) {
    if (state.passReports && state.passReports.length) {
      state.passReports = state.passReports.concat(reportsCollection.data)
    } else {
      state.passReports = reportsCollection.data
    }
  },
  SET_IS_REPORT_NEW(state, isReportNew) {
    state.isReportNew = isReportNew
  }
}

const actions = {
  getTotalPassReports(context, { page = 1, per_page = 10 }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/admin/reports/totalPasses", {
          params: {
            page,
            per_page
          }
        })
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  exportTotalPassReportCsv(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/admin/reports/totalPasses/${data}/download`, {
          params: data
        })
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  submitTotalPassReportRequest(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/admin/reports/totalPasses`, data)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getLatestReportStatus(context) {
    return new Promise((resolve, reject) => {
      axios
        .get('/admin/reports/totalPasses/report-status')
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
