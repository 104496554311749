<template>
  <div v-if="state.laddersObject" class="ladder-infraction-form">
    <div class="mb-3">
      <Label required>Ladder</Label>
      <CustomSelect
        :disabled="!!ladderType"
        v-model="state.selectedLadderType"
        :close-on-select="true"
        :options="ladderOptions"
        :reduce="(item) => item.value"
      ></CustomSelect>
    </div>
    <div class="pb-3">
      <div v-if="selectedLadder" class="select-list-wrapper">
        <div v-if="selectedLadderSteps" class="list">
          <div
            class="data-row d-flex align-items-center p-2"
            v-for="(infraction, index) in selectedLadderSteps"
            :key="index"
            :id="`infraction-${infraction.order}`"
          >
            <div class="flex-1">{{ infraction.order }}</div>
            <div class="px-2">
              <b>{{ getStepName(infraction) }}</b>
            </div>
            <div>
              <IconButton
                v-if="!isUpdateMode && infraction.order > maxReachedStepIndex"
                middle
                @click="removeStep(index)"
                icon="ri-delete-bin-line"
              ></IconButton>
            </div>
          </div>
        </div>
        <InfoBox v-else class="instructive p-3 text-center">
          <div><i class="ri-list-check mb-2"></i></div>
          <div>
            Add rows to your ladder by selecting infraction step and
            corresponding action.
          </div>
        </InfoBox>
      </div>
    </div>
    <div class="mb-4 d-flex align-items-start">
      <div class="me-3 flex-1">
        <Label required>Infraction Step</Label>
        <InputField
          :disabled="!!isUpdateMode || state.isProcessing"
          :invalid-feedback="errors.order"
          :is-invalid="!!errors.order"
          v-model="state.form.order"
        ></InputField>
      </div>
      <div class="flex-2">
        <div>
          <Label required>Actions</Label>
          <div class="d-flex">
            <CustomSelect
              :invalid-feedback="errors.infraction"
              :is-invalid="!!errors.infraction"
              v-model="state.form.infraction"
              :close-on-select="true"
              class="w-full me-2"
              @update:model-value="addStep"
              :options="infractionActions"
              :disabled="
                state.isProcessing || !state.form.order || errors.order
              "
            ></CustomSelect>
          </div>
        </div>
      </div>
    </div>
    <div>
      <InfoBox
        v-if="state.serverRes"
        class="mb-3"
        :class="{ danger: Object.keys(state.serverErrors).length }"
        :title="state.serverRes.title"
        >{{ state.serverRes.message }}</InfoBox
      >
    </div>
    <div class="text-center">
      <LoadingButton class="me-2 px-4" @click="onCancel" rounded
        >Cancel</LoadingButton
      >
      <LoadingButton
        v-if="isFromSubmitable"
        :is-loading="state.isProcessing"
        @click="onSubmit()"
        class="px-4"
        solid
        rounded
        :disabled="state.isProcessing"
        >Submit</LoadingButton
      >
      <LoadingButton
        v-else
        @click="createAndUpdateSteps"
        smaller
        icon="ri-add-line"
        class="solid full-rounded lh-normal mt-2"
        >Next</LoadingButton
      >
    </div>
  </div>
</template>

<script>
import { computed, reactive, onMounted, inject, nextTick, watch } from "vue"
import { useStore } from "vuex"
import { helpers, required, integer } from "@vuelidate/validators"
import useVuelidate from "@vuelidate/core"
import CustomSelect from "@/v3components/shared/Form/CustomSelect.vue"
import LoadingButton from "@/v3components/shared/Buttons/LoadingButton.vue"
import InfoBox from "@/v3components/shared/Alerts/InfoBox.vue"
import Label from "@/v3components/shared/Form/Label.vue"
import InputField from "@/v3components/shared/Form/InputField.vue"
import IconButton from "../shared/Buttons/IconButton.vue"

export default {
  name: "CreateLadderInfractionForm",
  components: {
    CustomSelect,
    LoadingButton,
    InfoBox,
    Label,
    InputField,
    IconButton
  },
  emits: ["cancel"],
  props: {
    editableStep: {
      type: Object,
      default: null
    },
    ladderType: {
      type: String,
      default: ""
    }
  },
  setup(props, { emit }) {
    const store = useStore()
    const state = reactive({
      laddersObject: null,
      selectedLadderType: null,
      isProcessing: false,
      serverErrors: {},
      serverRes: null,
      form: {
        order: null,
        infraction: null
      },
      ladderMutations: {
        delete: false,
        update: false,
        push: false
      }
    })
    const actionDialog = inject("actionDialog")

    //Ladders
    const ladders = computed(() => store.getters["consequencesLadder/ladders"])
    const detentions = computed(() => store.getters["detentions/detentions"])
    const isLadderEmpty = computed(
      () =>
        !state.laddersObject ||
        state.laddersObject[state.selectedLadderType].steps === null
    )
    const isLadderMutated = computed(() =>
      Object.keys(state.ladderMutations).some(
        (key) => state.ladderMutations[key]
      )
    )
    const ladderOptions = [
      {
        label: "LTS Ladder",
        value: "LTS"
      },
      {
        label: "LTC Ladder",
        value: "LTC"
      },
      {
        label: "Combined (LTS & LTC)",
        value: "both"
      }
    ]
    const selectedLadder = computed(() => {
      return state.selectedLadderType &&
        state.laddersObject[state.selectedLadderType]
        ? state.laddersObject[state.selectedLadderType]
        : null
    })
    const selectedLadderSteps = computed(() => {
      return selectedLadder.value && selectedLadder.value.steps
        ? cloneObject(selectedLadder.value.steps).sort(
            (first, second) => first.order - second.order
          )
        : null
    })
    const maxReachedStepIndex = computed(() => {
      return selectedLadder.value && selectedLadder.value.max_reached_step_index
        ? selectedLadder.value.max_reached_step_index
        : null
    })
    const cloneLaddersObject = () => {
      return cloneObject(ladders.value)
    }
    const cloneObject = (object) => {
      return JSON.parse(JSON.stringify(object))
    }

    // infractions
    const selectedStepsPositions = computed(() => {
      return selectedLadderSteps.value
        ? selectedLadderSteps.value.map((el) => el.order)
        : []
    })
    const defaultInfractionActions = [
      {
        label: "Free",
        value: {
          type: "FREE",
          action: null
        }
      },
      {
        label: "APT",
        value: {
          type: "APT",
          action: null
        }
      }
    ]
    const infractionActions = computed(() => {
      return [
        ...defaultInfractionActions,
        ...detentions.value.map((detention) => {
          return {
            label: detention.name,
            value: {
              type: "DETENTION",
              action: { id: detention.id, name: detention.name }
            }
          }
        })
      ]
    })

    const getStepName = (step) => {
      return step.action && step.action.name ? step.action.name : step.type
    }
    const pushStep = async (step) => {
      setLadderMutation("push", true)
      if (selectedLadderSteps.value) {
        if (step.order > selectedLadderSteps.value.length) {
          const len = Math.max(
            ...selectedLadderSteps.value.map((step) => step.order)
          )
          const count = step.order - len

          for (let i = 1; i < count; i++) {
            state.laddersObject[state.selectedLadderType].steps.push({
              type: "FREE",
              action: null,
              order: len + i
            })
          }
        }
        state.laddersObject[state.selectedLadderType].steps.push(step)
      } else {
        state.laddersObject[state.selectedLadderType].steps = [step]
        for (let i = 1; i < step.order; i++) {
          state.laddersObject[state.selectedLadderType].steps.push({
            type: "FREE",
            action: null,
            order: i
          })
        }
      }
      resetForm()
      await nextTick()
      scrollToSpecificStep(step)
    }
    const refreshStepsPositions = () => {
      selectedLadderSteps.value.sort((a, b) => a.order - b.order)

      let missingOrderFound = false
      for (let i = 0; i < selectedLadderSteps.value.length; i++) {
        if (selectedLadderSteps.value[i].order !== i + 1) {
          missingOrderFound = true
          break
        }
      }

      if (missingOrderFound) {
        for (let i = 0; i < selectedLadderSteps.value.length; i++) {
          if (selectedLadderSteps.value[i].order > i + 1) {
            state.laddersObject[state.selectedLadderType].steps =
              selectedLadderSteps.value.map((step, index) => {
                if (index >= i) {
                  step.order -= 1
                }
                return step
              })
            break
          }
        }
      }
    }
    const pushStepWithSelectedPosition = (step) => {
      state.laddersObject[state.selectedLadderType].steps.sort(
        (a, b) => a.order - b.order
      )

      pushStep(step)
    }
    const isStepPositionSelected = (step) => {
      return selectedStepsPositions.value.includes(step.order)
    }
    const addStep = () => {
      if (
        !isUpdateMode.value &&
        (state.ladderMutations.push || state.ladderMutations.delete)
      ) {
        createAndUpdateSteps()
      }
    }
    const createAndUpdateSteps = () => {
      return new Promise((resolve) => {
        if (isFormValid.value) {
          const step = {
            ...state.form.infraction.value,
            order: parseInt(state.form.order)
          }
          if (isUpdateMode.value) {
            updateStep(step)
            resolve()
          } else if (isStepPositionSelected(step)) {
            pushStepWithSelectedPosition(step)
            resolve()
          } else {
            pushStep(step)
            resolve()
          }
        } else {
          v$.value.$touch()
        }
      })
    }
    const removeStep = (index) => {
      setLadderMutation("delete", true)
      state.laddersObject[state.selectedLadderType].steps.splice(index, 1)
      refreshStepsPositions()
      v$.value.$reset()
    }
    const updateStep = (step) => {
      if (!step) {
        return
      }
      state.laddersObject[state.selectedLadderType].steps = state.laddersObject[
        state.selectedLadderType
      ].steps.map((el) => {
        if (el.order === step.order) {
          el = step
        }
        return el
      })
    }
    // Form
    const validationMessages = {
      required: "This field is required",
      integer: "This field must be a number",
      maxValue:
        "The step must be maximum with 12 positions greater than the last one",
      minValue: "The number must be greater than 0",
      maxReachedStepIndex: "A student is already on this step or a higher one"
    }
    const validations = computed(() => {
      return {
        form: {
          order: {
            maxValue: helpers.withMessage(
              validationMessages.maxValue,
              (value) => {
                return selectedStepsPositions.value.length
                  ? value <= selectedStepsPositions.value.length + 12
                  : value <= 12
              }
            ),
            minValue: helpers.withMessage(
              validationMessages.minValue,
              (value) => {
                return value > 0
              }
            ),
            maxReachedStepIndex: helpers.withMessage(
              validationMessages.maxReachedStepIndex,
              (value) => {
                return value > maxReachedStepIndex.value
              }
            ),
            integer: helpers.withMessage(validationMessages.integer, integer),
            required: helpers.withMessage(validationMessages.required, required)
          },
          infraction: {
            required: helpers.withMessage(validationMessages.required, required)
          }
        }
      }
    })

    const v$ = useVuelidate(validations.value.form, state.form)
    const isFormValid = computed(() => !v$.value.$invalid)
    const errors = computed(() => {
      const errorObj = {}
      v$.value.$errors.forEach((err) => {
        errorObj[err.$property] = err.$message || true
      })

      return errorObj
    })

    const isUpdateMode = computed(() => !!props.editableStep)
    const isFromSubmitable = computed(
      () =>
        state.ladderMutations.push ||
        state.ladderMutations.delete ||
        isUpdateMode.value ||
        isLadderEmpty.value
    )

    //watchers
    watch(
      () => state.form.order,
      (value) => {
        setTimeout(() => {
          if (!value) {
            state.form.infraction = null
          }
        }, 0)
      }
    )
    watch(
      () => errors.value.order,
      (value) => {
        setTimeout(() => {
          if (value) {
            state.form.infraction = null
          }
        }, 0)
      }
    )

    const resetForm = () => {
      state.form = Object.assign(state.form, {
        order: null,
        infraction: null
      })
      v$.value.$reset()
    }
    const onCancel = () => {
      emit("cancel")
    }
    const setResponseInfoBox = (title, message) => {
      if (title || message) {
        state.serverRes = {
          message,
          title
        }
      } else {
        state.serverRes = null
      }
    }
    const onSubmit = async () => {
      if (isLadderEmpty.value) {
        await createAndUpdateSteps()
        submit()
      } else if (isUpdateMode.value) {
        actionDialog.open(setLadderAndSubmit, {
          args: {},
          props: {
            danger: true,
            title: "This change will affect the student already on the ladder.",
            question: "Are you sure you want to proceed?"
          }
        })
      } else if (isLadderMutated.value) {
        actionDialog.open(submit, {
          args: {},
          props: {
            danger: true,
            title: "This change will affect the student already on the ladder.",
            question: "Are you sure you want to proceed?"
          }
        })
      } else {
        submit()
      }
    }
    const setLadderAndSubmit = async () => {
      await createAndUpdateSteps()
      submit()
    }
    const submit = () => {
      state.isProcessing = true
      store
        .dispatch("consequencesLadder/updateLadders", state.laddersObject)
        .then((res) => {
          const data = res.data
          if (data && data.data) {
            setResponseInfoBox("Success", "The ladder is successfully updated!")
            store.commit("consequencesLadder/SET_LADDERS", data.data)
            state.isProcessing = false
            setTimeout(() => {
              setResponseInfoBox()
              setLadderMutation(null)
              emit("cancel")
            }, 1800)
          }
        })
        .catch((err) => {
          const res = err.response.data
          state.serverErrors = res.errors || {}
          setResponseInfoBox("Error", res.message)
          state.isProcessing = false
        })
    }
    const setLadderMutation = (mutation, value) => {
      if (!mutation || mutation === null) {
        state.ladderMutations = {
          delete: false,
          update: false,
          push: false
        }
      } else if (
        Object.prototype.hasOwnProperty.call(state.ladderMutations, mutation)
      ) {
        state.ladderMutations[mutation] = !!value
      }
    }
    const scrollToSpecificStep = (step = { order: 1 }) => {
      try {
        document.getElementById("infraction-" + step.order).scrollIntoView()
      } catch (error) {
        throw new Error(error)
      }
    }
    onMounted(() => {
      setTimeout(async () => {
        state.selectedLadderType = props.ladderType || ladderOptions[0].value
        state.laddersObject = cloneLaddersObject()
        if (props.editableStep) {
          state.form = Object.assign(state.form, {
            infraction: {
              label: props.editableStep.action
                ? props.editableStep.action.name
                : props.editableStep.type,
              value: {
                type: props.editableStep.type,
                action: props.editableStep.action
              }
            },
            order: parseInt(props.editableStep.order)
          })
          await nextTick()
          scrollToSpecificStep(state.form)
        }
        if (!detentions.value.length) {
          store.dispatch("detentions/getDetentions")
        }
      }, 0)
    })

    return {
      infractionActions,
      state,
      createAndUpdateSteps,
      removeStep,
      onCancel,
      onSubmit,
      errors,
      ladderOptions,
      selectedLadder,
      selectedLadderSteps,
      getStepName,
      isUpdateMode,
      isFromSubmitable,
      addStep,
      maxReachedStepIndex
    }
  }
}
</script>
