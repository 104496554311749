<template>
  <div>
    <!-- SERVER ERROR -->
    <VisitorErrorHandler
      v-if="serverError != null"
      keep-short
      center
      :error="serverError"
      @done="serverError = null"
    />
    <!-- SERVER ERROR -->

    <div
      v-else
      class="d-flex flex-wrap gap-1"
      title="Click to view match or allow visitor"
      :class="statusClasses"
      @click.stop="onVisitorStatusClick"
    >
      <div
        class="d-flex flex-wrap justify-content-center align-content-center gap-2 visitor-status-pill"
      >
        <i class="visitor-status-icon" :class="iconStyleClasses"></i>
        <span class="mt-3px text-capitalize">
          {{ isLoading ? "Loading..." : visitor.visit_status }}
        </span>
      </div>
      <i
        v-if="statusTooltip.length > 0"
        :title="statusTooltip"
        class="m-auto"
        :class="{
          'ri-information-line': !isBadgePrintingFailed,
          'ri-printer-line visitor-status-red': isBadgePrintingFailed
        }"
      ></i>
    </div>
  </div>
</template>

<script setup>
import VisitorErrorHandler from "@/v3components/VisitorErrorHandler.vue"
import visitorConfigs from "@/configs/visitorConfigs"
import visitorConstants from "@/constants/visitorConstants"
import passHelpers from "@/helpers/index"
import moment from "moment-timezone"
import { computed, defineAsyncComponent, defineProps, inject, ref } from "vue"
import { useStore } from "vuex"

const VisitorMatchPopUp = defineAsyncComponent(
  () => import("@/v3components/Forms/Visitor/CheckIns/VisitorMatchPopUp.vue")
)

const VisitorExceptionReasonPopUp = defineAsyncComponent(
  () =>
    import(
      "@/v3components/Forms/Visitor/CheckIns/VisitorExceptionReasonPopUp.vue"
    )
)

const props = defineProps({
  visitor: {
    type: Object,
    default: () => ({})
  },
  isBadgePrintingFailed: {
    type: Boolean,
    default: false
  },
  statusTooltip: {
    type: String,
    default: ""
  }
})

const store = useStore()
const modal = inject("modal")

const serverError = ref(null)
const isLoading = ref(false)
const exceptionMessage = ref("")

const statusClasses = computed(() => {
  if (isLoading.value) {
    return "visitor-status-gray"
  }
  return `visitor-status-red cursor-pointer`
})

const iconStyleClasses = computed(() => {
  if (isLoading.value) {
    return "loading ri-loader-2-line"
  }
  return "denied ri-close-line"
})

const formatDateTime = (date) => {
  const datePart = date?.split(" ")?.[0] || ""
  const timePart = date?.split(" ")?.[1] || ""
  return visitorConfigs?.useCustomVisitorTimeFunction
    ? passHelpers.convertToCurrentTimezone(date)
    : passHelpers.currTzDateTime(datePart, timePart, "MM-DD-YYYY h:mm A")
}

/**
 * Show action for allowing denied visitor if it's still the same day
 * the visitor was denied (signed-in date)
 */
const showActionForAllowingDeniedVisitor = computed(() => {
  const localSignedInTime = formatDateTime(props?.visitor?.signed_in_date)

  const localCurrentTime = visitorConfigs?.useCustomVisitorTimeFunction
    ? passHelpers.convertToCurrentTimezone(moment(), false, "MM-DD-YYYY h:mm A")
    : moment().format("MM-DD-YYYY h:mm A")

  const signedInDay = moment(localSignedInTime).startOf("day")
  const currentDay = moment(localCurrentTime).startOf("day")
  const diffInDays = currentDay.diff(signedInDay, "days")
  return Number(diffInDays) < 1
})

const watchlistOffenderPayload = computed(() => {
  return {
    firstName: props?.visitor?.first_name,
    lastName: props?.visitor?.last_name,
    dateOfBirth: props?.visitor?.profile?.date_of_birth
  }
})

const checkOffender = async (payload) => {
  try {
    isLoading.value = true
    const result = await store.dispatch("visitorManage/checkOffender", {
      ...payload
    })
    return {
      result,
      success: true
    }
  } catch (error) {
    serverError.value = error
    return {
      result: null,
      success: false
    }
  } finally {
    isLoading.value = false
  }
}

const checkWatchlist = async (payload) => {
  try {
    isLoading.value = true
    const result = await store.dispatch("visitorManage/checkWatchlist", {
      ...payload
    })
    return {
      result,
      success: true
    }
  } catch (error) {
    serverError.value = error
    return {
      result: null,
      success: false
    }
  } finally {
    isLoading.value = false
  }
}

const onVisitorStatusClick = () => {
  if (isLoading.value) {
    return
  }
  return showWatchlistOffenderMatchForm()
}

const openMatchPopUp = ({
  title,
  description,
  watchList,
  offenderList,
  isOffenderType
}) => {
  modal.open(VisitorMatchPopUp, {
    size: "lg",
    props: {
      useDifferentKeyForVisitorDob: true,
      fromDeniedButton: true,
      showAllowAction: showActionForAllowingDeniedVisitor.value,
      type: isOffenderType
        ? visitorConstants.CHECK_IN_FORM.MATCH_TYPE_OFFENDER
        : visitorConstants.CHECK_IN_FORM.MATCH_TYPE_WATCHLIST,
      title,
      description,
      note: visitorConstants.CHECK_IN_FORM.MODAL_ALLOW_RISK_NOTE,
      visitor: props?.visitor,
      watchList,
      offenderList,
      allow: (matchedUser) => {
        const matchType =
          (matchedUser?.type?.toLowerCase()?.trim() || "") ===
          visitorConstants.CHECK_IN_FORM.MATCH_TYPE_OFFENDER
            ? visitorConstants.CHECK_IN_FORM.MATCH_TYPE_OFFENDER
            : visitorConstants.CHECK_IN_FORM.MATCH_TYPE_WATCHLIST

        return showVisitorWOVExceptionReasonForm({
          match_type: matchType
        })
      }
    }
  })
}

const showWatchlistOffenderMatchForm = async () => {
  try {
    const { result: offenderList, success: offenderSuccess } =
      await checkOffender(watchlistOffenderPayload?.value)
    if (!offenderSuccess) {
      return
    }

    const { result: watchList, success: watchListSuccess } =
      await checkWatchlist(watchlistOffenderPayload?.value)
    if (!watchListSuccess) {
      return
    }

    const isBoth = offenderList?.length > 0 && watchList?.length > 0
    const isOffenderType = offenderList?.length > 0 ? true : false

    const title = isBoth
      ? visitorConstants.CHECK_IN_FORM
          .MODAL_TITLE_WATCHLIST_OR_OFFENDER_MATCH_ALL
      : isOffenderType
        ? visitorConstants.CHECK_IN_FORM.MODAL_TITLE_OFFENDER_MATCH
        : visitorConstants.CHECK_IN_FORM.MODAL_TITLE_WATCHLIST_MATCH
    const description = isBoth
      ? visitorConstants.CHECK_IN_FORM
          .MODAL_DESCRIPTION_WATCHLIST_OR_OFFENDER_MATCH_ALLOW
      : isOffenderType
        ? visitorConstants.CHECK_IN_FORM.MODAL_DESCRIPTION_OFFENDER_MATCH_ALLOW
        : visitorConstants.CHECK_IN_FORM.MODAL_DESCRIPTION_WATCHLIST_MATCH_ALLOW

    const noResultsFound = offenderList.length === 0 && watchList.length === 0

    if (noResultsFound) {
      throw new Error("No match found")
    }

    return openMatchPopUp({
      title,
      description,
      watchList,
      offenderList,
      isOffenderType
    })
  } catch (error) {
    serverError.value = error
  } finally {
    isLoading.value = false
  }
}

const showVisitorWOVExceptionReasonForm = (payload) => {
  const matchType =
    (payload?.match_type?.toLowerCase()?.trim() || "") ===
    visitorConstants.CHECK_IN_FORM.MATCH_TYPE_OFFENDER
      ? visitorConstants.CHECK_IN_FORM.MATCH_TYPE_OFFENDER
      : visitorConstants.CHECK_IN_FORM.MATCH_TYPE_WATCHLIST
  modal.open(VisitorExceptionReasonPopUp, {
    size: "sm",
    hideCloseButton: true,
    props: {
      type: matchType + "_allow",
      signIn: async (reason) => {
        exceptionMessage.value = reason

        const { success } = await updateVisitor()
        if (!success) {
          return
        }

        return refreshVisitorLogs()
      },
      deny: () => {
        return
      }
    }
  })
}

const updateVisitor = async () => {
  try {
    isLoading.value = true
    await store.dispatch("visitorManage/updateVisitor", {
      visit_id: props?.visitor?.visit_id || null,
      visitor_id: props?.visitor?.visitor_id || null,
      visit_status: visitorConstants.CHECK_IN_FORM.VISIT_STATUS_SIGNED_IN,
      exception_message:
        exceptionMessage?.value ||
        props?.visitor?.signin_exception_message ||
        ""
    })
    return {
      success: true
    }
  } catch (error) {
    serverError.value = error
    return {
      success: false
    }
  } finally {
    isLoading.value = false
  }
}

const refreshVisitorLogs = () => {
  return store.commit("visitorManage/SET_UPDATE_VISITOR_LOGS", true)
}
</script>

<style lang="scss" scoped>
.mt-3px {
  margin-top: 3px;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
