<template>
  <CContainer>
    <Loader :is-processing="isLoading" />

    <CRow class="mt-4">
      <CCol md="12">
        <ActionPanel
          @tab-clicked="setActiveTableTab"
          show-sort
          class="mt-3"
          show-export
          show-search
          @on-search="searchByQuery"
          :search-v-model="state.columnSearchKeys.globalQuery"
          :tabs="state.tableTabs"
          @on-sort="toggleFilter"
          @on-export="
            handleLocationRestrictions(state.pagination.activePage, true, true)
          "
        >
          <template #bottom-content>
            <CRow class="mt-3" v-if="state.isFilterVisible">
              <CCol class="justify-content-start">
                <CustomCheck
                  class="mt-2"
                  label="Student Number"
                  v-model="state.filterParams.checkBoxes.student_sis_id"
                  @change="changeSN"
                ></CustomCheck>
              </CCol>
              <CCol class="justify-content-end text-end">
                <BaseButton
                  class=""
                  solid
                  rounded
                  @click="handleLocationRestrictions()"
                  >Filter</BaseButton
                >
                <BaseButton class="ms-2" rounded @click="clearAll"
                  >Clear all</BaseButton
                >
              </CCol>
            </CRow>
          </template>
        </ActionPanel>
      </CCol>
      <div class="d-flex">
        <CCol class="w-auto mt-2 pr-3 mb-2 ms-1 d-flex">
          <BulkAction
            :actions="state.bulkActions"
            @click-action="handleBulkAction($event)"
          ></BulkAction>
        </CCol>
        <CCol class="w-auto mt-2 mb-2 d-flex justify-content-end">
          <PerPage
            v-if="state.pagination"
            :pagination="state.pagination"
            :showing-entries="true"
            @on-page-change="setPerPage"
          />
        </CCol>
      </div>
    </CRow>

    <DataTable
      v-if="locationRestrictions"
      :items="activeLocationRestriction"
      :fields="activeDataFields"
      :sorter="{ external: true }"
      column-filter
      :no-items-view="{
        noResults: 'No items available',
        noItems: 'No items available'
      }"
      @update:sorter-value="sortByColumn"
    >
      <template #search_column>
        <div class="sort-icon-v3">
          <i class="ri-search-line" @click="toggleSearchByColumn"></i>
        </div>
      </template>

      <template #delete-header="{}">
        <div>
          <CustomCheck
            :model-value="state.deleteCheckbox"
            @update:model-value="toggleAllForDelete"
          />
        </div>
      </template>

      <template #delete="{ item }">
        <td class="mw-auto">
          <CustomCheck
            :model-value="state.deleteCollection.includes(item.id)"
            @update:model-value="toggleRestrictionForDelete(item)"
          />
        </td>
      </template>

      <template #student_sis_id="{ item }">
        <td>
          <div>
            {{
              item.user && item.user.student_sis_id
                ? item.user.student_sis_id
                : ""
            }}
          </div>
        </td>
      </template>

      <template #location="{ item }">
        <td>
          <p class="m-0">
            {{ item.location }}
          </p>
        </td>
      </template>
      <template #location-filter>
        <input
          v-show="state.filter"
          v-model="state.columnSearchKeys.location.value"
          placeholder="Search by name"
          class="form-control form-control-sm my-2"
          @input="searchByQuery($event.target.value, 'column')"
        />
      </template>

      <template #from_date="{ item }">
        <td>
          {{ item.from_date ? item.from_date : "-" }}
        </td>
      </template>
      <template #from_date-filter>
        <input
          v-show="state.filter"
          v-model="state.columnSearchKeys.from_date.value"
          placeholder="Search by date"
          class="form-control form-control-sm my-2"
          @input="searchByQuery($event.target.value, 'column')"
        />
      </template>

      <template #to_date="{ item }">
        <td>
          {{ item.to_date ? item.to_date : "-" }}
        </td>
      </template>
      <template #to_date-filter>
        <input
          v-show="state.filter"
          v-model="state.columnSearchKeys.to_date.value"
          placeholder="Search by date"
          class="form-control form-control-sm my-2"
          @input="searchByQuery($event.target.value, 'column')"
        />
      </template>

      <template #room="{ item }">
        <td>
          <b v-if="item.room">{{ item.room.name }}</b>
        </td>
      </template>
      <template #room-filter>
        <input
          v-show="state.filter"
          v-model="state.columnSearchKeys.room.value"
          placeholder="Search by name"
          class="form-control form-control-sm my-2"
          @input="searchByQuery($event.target.value, 'column')"
        />
      </template>

      <template #type="{ item }">
        <td>
          <div>
            <b>
              <i
                class="me-1"
                :class="
                  item.type == 'access_denied'
                    ? 'flaticon-forbidden'
                    : 'flaticon-profile-user'
                "
              />
            </b>
          </div>
          <div>
            {{
              item.type === "access_denied" ? "Access Denied" : "Members Only"
            }}
          </div>
        </td>
      </template>
      <template #type-filter>
        <input
          v-show="state.filter"
          v-model="state.columnSearchKeys.type.value"
          placeholder="Search by limitation"
          class="form-control form-control-sm my-2"
          @input="searchByQuery($event.target.value, 'column')"
        />
      </template>

      <template #status="{ item }">
        <td>
          <div @click="toggleLocationRestriction(item.id)">
            <StatusPill
              :status="item.status ? 'STATUS_ACTIVE' : 'STATUS_INACTIVE'"
              :status-icon="item.status ? 'ri-check-line' : 'ri-close-line'"
            >
            </StatusPill>
          </div>
        </td>
      </template>
      <template #status-filter>
        <input
          v-show="state.filter"
          v-model="state.columnSearchKeys.status.value"
          placeholder="Search by status"
          class="form-control form-control-sm my-2"
          @input="searchByQuery($event.target.value, 'column')"
        />
      </template>

      <template #action="{ item }">
        <td class="text-end">
          <Actions
            v-if="item.status"
            :actions="activeLocationsActions"
            @click-action="handleTableAction($event, item)"
          >
          </Actions>
          <Actions
            v-else
            :actions="inactiveLocationsActions"
            @click-action="handleTableAction($event, item)"
          >
          </Actions>
        </td>
      </template>
      <template #action-filter>
        <div />
      </template>
    </DataTable>

    <Pagination
      v-if="
        state.pagination.pages &&
        state.pagination.pages > 1 &&
        !state.isLazyLoadingMode
      "
      class="cs-pagination mt-4"
      :dots="false"
      :double-arrows="true"
      :active-page="state.pagination.activePage"
      :pages="state.pagination.pages"
      size="lg"
      align="center"
      @update:active-page="setActivePage"
    >
    </Pagination>
  </CContainer>
</template>
<script>
import Loader from "@/v3components/shared/Loader/Loader.vue"
import ActionPanel from "@/v3components/shared/DataTable/ActionPanel.vue"
import CustomCheck from "@/v3components/shared/Form/CustomCheck.vue"
import BaseButton from "@/v3components/shared/Buttons/BaseButton.vue"
import PerPage from "@/v3components/shared/DataTable/Perpage.vue"
import DataTable from "@/v3components/shared/DataTable/DataTable.vue"
import Pagination from "@/v3components/shared/DataTable/Pagination.vue"
import StatusPill from "@/v3components/shared/DataTable/StatusPill.vue"
import Actions from "@/v3components/shared/DataTable/Actions.vue"
import BulkAction from "@/v3components/shared/DataTable/BulkAction.vue"
import helpers from "../../helpers/index.js"
import { useStore } from "vuex"
import { onMounted, computed, reactive, inject, watch } from "vue"
import download from "@/helpers/downloadCSV.js"

export default {
  name: "LimitLocationAvailabilityDataTable",
  components: {
    Loader,
    ActionPanel,
    CustomCheck,
    BaseButton,
    PerPage,
    BulkAction,
    DataTable,
    Pagination,
    StatusPill,
    Actions
  },
  setup() {
    const store = useStore()

    const actionDialog = inject("actionDialog")
    const modal = inject("modal")

    const state = reactive({
      filter: false,
      bulkActions: [
        {
          id: 1,
          text: "Delete",
          icon: "ri-delete-bin-line",
          action: "delete"
        }
      ],
      isFilterVisible: false,
      isLazyLoadingMode: false,
      deleteCheckbox: false,
      deleteCollection: [],
      columnSearchKeys: {
        globalQuery: "",
        location: { value: "", column: ["location"], sortBy: "location" },
        from_date: {
          value: "",
          column: ["from_date.raw"],
          sortBy: "from_date"
        },
        to_date: { value: "", column: ["to_date.raw"], sortBy: "to_date" },
        room: { value: "", column: ["room.name"], sortBy: "room.name" },
        type: { value: "", column: ["type"], sortBy: "type" },
        status: { value: "", column: ["status"], sortBy: "status" }
      },
      filterParams: {
        search_query: "",
        sort_query: "",
        activeTab: "",
        checkBoxes: {
          student_sis_id: false
        }
      },
      pagination: {
        activePage: 1,
        total: 0,
        pages: 0,
        per_page: { label: "25", value: 25 }
      },
      dataTableFields: [
        {
          key: "delete",
          label: "",
          filter: false,
          _style: "width:50px !important"
        },
        {
          key: "student_sis_id",
          filter: false,
          sorter: false,
          label: "Student number",
          tabs: ["active", "inactive"],
          _style: "min-width:150px"
        },
        { key: "location", label: "Student" },
        { key: "from_date", _style: "min-width:135px", label: "Start Date" },
        { key: "to_date", _style: "min-width:130px", label: "End Date" },
        { key: "room", label: "Location" },
        {
          key: "type",
          label: "Limitation",
          _style: "min-width:120px"
        },
        { key: "status", label: "Status", sorter: false },
        {
          key: "action",
          label: "Action",
          _style: "width: 50px;",
          filter: false,
          sorter: false
        }
      ],
      tableTabs: [
        { label: "Active", value: "active", status: 1 },
        { label: "Inactive", value: "inactive", status: 0 }
      ],
      isLoading: false,
      modal: {
        isVisible: {
          edit: false
        },
        activePass: null
      }
    })

    const locationRestrictions = computed(
      () => store.getters["rooms/locationRestrictions"]
    )

    const locationRestrictionsIDCollection = computed(() =>
      locationRestrictions.value.map((el) => el.id)
    )

    const totalPassesCount = computed(() =>
      state.pagination.total > locationRestrictions.value.length
        ? state.pagination.total
        : locationRestrictions.value.length
    )

    const activeLocationRestriction = computed(() =>
      locationRestrictions.value.filter(
        (item) => item.status == state.filterParams.activeTab.status
      )
    )

    const activeLocationsActions = [
      { label: "End", icon: "ri-close-circle-line", action: "end" },
      {
        label: "Delete",
        icon: "ri-delete-bin-line",
        class: "important",
        action: "delete"
      }
    ]

    const inactiveLocationsActions = [
      {
        label: "Delete",
        icon: "ri-delete-bin-line",
        class: "important",
        action: "delete"
      }
    ]

    onMounted(() => {
      state.filterParams.activeTab = state.tableTabs[0]
      handleLocationRestrictions()
      initLazyLoading()
    })

    const handleLocationRestrictions = (
      page,
      isExport,
      noLoader,
      isLazyLoadingMode
    ) => {
      state.isLoading = !noLoader
      state.pagination.activePage = page ? page : 1
      const params = {
        page: state.pagination.activePage,
        per_page: state.pagination.per_page.value
      }
      if (state.filterParams.sort_query) {
        params.sort = state.filterParams.sort_query
      }
      if (state.filterParams.search_query) {
        params.search_query = state.filterParams.search_query
      }
      if (state.filterParams.activeTab) {
        if (state.filterParams.activeTab.value == "active") {
          params.active = true
        }
        if (state.filterParams.activeTab.value == "inactive") {
          params.inactive = true
        }
      }
      if (isExport) {
        exportCSVLocationRestrictions(params)
      } else {
        getLocationRestrictions(params, noLoader, isLazyLoadingMode)
      }
    }

    const getLocationRestrictions = (params, noLoader, isLazyLoadingMode) => {
      state.isLoading = !noLoader
      store
        .dispatch("rooms/getLocationRestrictions", params)
        .then((response) => {
          const data = response.data
          if (data) {
            if (isLazyLoadingMode) {
              store.commit("rooms/PUSH_LOCATION_RESTRICTIONS", data.data)
            } else {
              store.commit("rooms/SET_LOCATION_RESTRICTIONS", data.data)
            }
            state.pagination.total = data.meta.total
            state.pagination.from = data.meta.from
            state.pagination.to = data.meta.to
            state.pagination.pages = Math.ceil(
              data.meta.total / data.meta.per_page
            )
            state.pagination.activePage = Number(state.pagination.activePage)
            state.pagination = JSON.parse(JSON.stringify(state.pagination))
          }
          state.isLoading = false
        })
        .catch(() => {
          state.isLoading = false
        })
    }

    const exportCSVLocationRestrictions = (params) => {
      params.per_page = [25, 50, 100].includes(state.pagination.per_page.value)
        ? state.pagination.per_page.value
        : "all"
      state.isLoading = true
      store
        .dispatch("rooms/getCSVlocationRestrictionsExport", {
          ...params,
          student_sis_id: state.filterParams.checkBoxes.student_sis_id
        })
        .then((response) => {
          if (response.data) {
            download.CSVExport(response.data, "location-restrictions")
          }
          state.isLoading = false
        })
        .catch(() => {
          state.isLoading = false
        })
    }

    const searchByQuery = (value, type) => {
      clearInterval(state.searchTimeOut)
      state.searchTimeOut = setTimeout(() => {
        handleQuerySearch(value, type)
      }, 500)
    }

    const handleQuerySearch = (value, type) => {
      const query = value.toString()
      if (
        query.replace(/\s/g, "").length > 0 ||
        query.replace(/\s/g, "").length === 0
      ) {
        state.filterParams.search_query = ""
        if (type && type === "column") {
          state.columnSearchKeys.globalQuery = ""
          for (const key in state.columnSearchKeys) {
            if (Object.hasOwnProperty.call(state.columnSearchKeys, key)) {
              const columnData = state.columnSearchKeys[key]
              if (columnData.value) {
                columnData.column.forEach((col) => {
                  state.filterParams.search_query =
                    col === "location"
                      ? state.filterParams.search_query +
                        `${col}:"${columnData.value}"|inverted_location:"${columnData.value}", `
                      : state.filterParams.search_query +
                        `${col}:"${columnData.value}", `
                })
              }
            }
          }
          state.filterParams.search_query =
            state.filterParams.search_query.slice(0, -2)
        } else {
          resetSearchQuery()
          state.columnSearchKeys.globalQuery = query
          state.filterParams.search_query = query ? '"' + query + '"' : query
        }
        handleLocationRestrictions()
      }
    }

    const sortByColumn = (column) => {
      const columnName = state.columnSearchKeys[column.column]
        ? state.columnSearchKeys[column.column].sortBy
        : column.column
      state.filterParams.sort_query = `${columnName}:${
        column.asc ? "asc" : "desc"
      }`
      handleLocationRestrictions()
    }

    const toggleLocationRestriction = (id) => {
      if (id) {
        store
          .dispatch("rooms/toggleLocationRestriction", id)
          .then((response) => {
            const data = response.data.data
            if (data) {
              store.commit("rooms/UPDATE_LOCATION_RESTRICTION", data)
            }
          })
      }
    }

    const deleteAllRestrictions = () => {
      store
        .dispatch("rooms/deleteAllRestrictions")
        .then(() => {
          state.isLoading = false
          state.deleteCollection = []
          state.deleteCheckbox = false
          handleLocationRestrictions()
        })
        .catch(() => (state.isLoading = false))
    }

    const deleteLocationRestriction = (id, collection) => {
      if (id) {
        state.isLoading = true
        store
          .dispatch("rooms/deleteLocationRestriction", id)
          .then(() => {
            handleLocationRestrictions()
            state.isLoading = false
            state.pagination.total = state.pagination.total - 1
          })
          .catch(() => (state.isLoading = false))
      }
      if (collection && collection.length) {
        state.isLoading = true
        store
          .dispatch("rooms/deleteLocationRestrictions", collection)
          .then(() => {
            handleLocationRestrictions()
            state.deleteCollection = []
            state.deleteCheckbox = false
          })
          .catch(() => (state.isLoading = false))
      }
    }

    const deleteLocationRestrictions = (collection) => {
      if (collection && collection.length) {
        state.isLoading = true
        store
          .dispatch("rooms/deleteLocationRestrictions", collection)
          .then(() => {
            handleLocationRestrictions()
            state.deleteCollection = []
            state.deleteCheckbox = false
          })
          .catch(() => (state.isLoading = false))
      }
    }

    const setPerPage = (option) => {
      if (option) {
        state.isLazyLoadingMode = option.label === "All entries"
        state.pagination.per_page = option
        handleLocationRestrictions()
      }
    }

    const setActivePage = (page) => {
      state.pagination.activePage = page
      handleLocationRestrictions(state.pagination.activePage)
      helpers.scrollToTop()
    }

    const setActiveTableTab = (tab) => {
      resetSearchQuery()
      resetFilterValues()
      state.filterParams.activeTab = tab
      helpers.scrollToTop()
      handleLocationRestrictions()
    }

    const resetFilterValues = () => {
      state.isFilterVisible = false
      state.filterParams.checkBoxes.student_sis_id = false
    }

    const resetSearchQuery = () => {
      state.filterParams.search_query = ""
      state.columnSearchKeys = {
        globalQuery: "",
        student_sis_id: {
          value: "",
          column: ["student_sis_id"],
          sortBy: "student_sis_id"
        },
        location: { value: "", column: ["location"], sortBy: "location" },
        from_date: {
          value: "",
          column: ["from_date.raw"],
          sortBy: "from_date"
        },
        to_date: { value: "", column: ["to_date.raw"], sortBy: "to_date" },
        room: { value: "", column: ["room.name"], sortBy: "room.name" },
        type: { value: "", column: ["type"], sortBy: "type" },
        status: { value: "", column: ["status"], sortBy: "status" }
      }
    }

    const resetParams = () => {
      state.filterParams = {
        search_query: "",
        sort_query: "",
        checkBoxes: {
          student_sis_id: false
        },
        activeTab: state.tableTabs[0]
      }
    }

    const refreshDataTable = () => {
      resetParams()
      resetSearchQuery()
      handleLocationRestrictions(1, false, true)
    }

    const toggleAllForDelete = () => {
      state.deleteCollection = state.deleteCheckbox
        ? state.locationRestrictionsIDCollection
        : []

      state.deleteCheckbox = !state.deleteCheckbox
      state.deleteCollection = state.deleteCheckbox
        ? locationRestrictionsIDCollection.value
        : []
    }

    const toggleRestrictionForDelete = (item) => {
      if (
        state.deleteCollection.length &&
        state.deleteCollection.includes(item.id)
      ) {
        state.deleteCollection = state.deleteCollection.filter(
          (el) => el != item.id
        )
      } else {
        state.deleteCollection.push(item.id)
      }
    }

    const initLazyLoading = () => {
      const body = document.getElementsByClassName("main-scroll-container")[0]
      if (body) {
        body.onscroll = () => {
          if (
            state.isLazyLoadingMode &&
            locationRestrictions.value &&
            locationRestrictions.value.length &&
            state.pagination.total > locationRestrictions.value.length
          ) {
            if (body.offsetHeight + body.scrollTop + 1 >= body.scrollHeight) {
              state.pagination.activePage = state.pagination.activePage + 1
              handleLocationRestrictions(
                state.pagination.activePage,
                false,
                false,
                true
              )
            }
          }
        }
      }
    }

    const toggleFilter = () => {
      state.isFilterVisible = !state.isFilterVisible
    }

    const clearAll = () => {
      resetFilterValues()
      handleLocationRestrictions()
    }

    const handleBulkAction = (event) => {
      if (event.action === "delete") {
        actionDialog.open(deleteLocationRestrictions, {
          args: state.deleteCollection,
          props: {
            danger: true,
            title: "Bulk delete",
            question: "Are you sure?"
          }
        })
      }
    }

    const toggleSearchByColumn = () => {
      state.filter = !state.filter
    }

    const handleTableAction = (event, item) => {
      if (event.action == "delete") {
        actionDialog.open(deleteLocationRestriction, {
          args: item.id,
          props: { danger: true, title: "Delete", question: "Are you sure?" }
        })
      }
      if (event.action == "end") {
        actionDialog.open(toggleLocationRestriction, {
          args: item.id,
          props: { danger: true, title: "End", question: "Are you sure?" }
        })
      }
    }

    const changeSN = () => {
      state.filterParams.checkBoxes.student_sis_id =
        !state.filterParams.checkBoxes.student_sis_id
    }

    watch(
      () => modal.options.action,
      (isAction) => {
        if (isAction == "createLocationLimit") {
          handleLocationRestrictions(state.pagination.activePage, false, true)
        }
      }
    )

    const activeDataFields = computed(() => {
      return state.dataTableFields.filter((field) =>
        !state.filterParams.checkBoxes.student_sis_id
          ? !field.key.includes("student_sis_id")
          : field
      )
    })

    return {
      state,
      locationRestrictions,
      locationRestrictionsIDCollection,
      totalPassesCount,
      activeLocationRestriction,
      handleLocationRestrictions,
      getLocationRestrictions,
      searchByQuery,
      handleQuerySearch,
      sortByColumn,
      toggleLocationRestriction,
      deleteAllRestrictions,
      deleteLocationRestriction,
      setPerPage,
      setActivePage,
      setActiveTableTab,
      resetSearchQuery,
      resetParams,
      refreshDataTable,
      toggleAllForDelete,
      toggleRestrictionForDelete,
      initLazyLoading,
      toggleFilter,
      clearAll,
      handleBulkAction,
      toggleSearchByColumn,
      activeLocationsActions,
      inactiveLocationsActions,
      handleTableAction,
      changeSN,
      activeDataFields
    }
  }
}
</script>
