<template>
  <div class="v3-datatable-action-panel">
    <div class="d-flex align-items-center justify-content-between">
      <div class="action-left d-flex align-items-center">
        <div v-if="tabs && tabs.length" class="action-panel-tabs">
          <PillButton
            data-test-id="action-panel-tab"
            :active="state.activeTab && state.activeTab.label === tab.label"
            :class="{
              'rounded-start': i == 0,
              'rounded-end': i == tabs.length - 1
            }"
            @click="setActiveTab(tab, i)"
            v-for="(tab, i) in tabs"
            :key="i"
            >{{ tab.label }}</PillButton
          >
        </div>
        <div v-if="showTopLeftSlot" class="action-panel-left-slot ms-1">
          <slot name="first-top-left-content"></slot>
        </div>
        <div v-if="showSearch" class="action-panel-search ms-1">
          <InputField
            data-test-id="action-panel-search-input"
            prepend-icon="ri-search-line"
            placeholder="Search"
            :model-value="searchVModel"
            @update:model-value="onSearch"
          ></InputField>
        </div>
        <div v-if="showSort" class="action-panel-sort ms-1">
          <IconButton @click="onSort" icon="ri-filter-line"></IconButton>
        </div>
        <slot name="left-content"></slot>
        <HelpCenterButton
          v-if="helpButton"
          classes="ms-2"
          :content-key="helpButtonContentKey"
          :is-old="true"
        />
      </div>
      <div class="action-right d-flex align-items-center justify-content-end">
        <slot name="right-content"></slot>
        <div v-if="showExport" class="action-panel-sort ms-1">
          <IconButton
            @click="onExportClick"
            :icon="exportLoading ? `ri-loader-4-line` : `ri-download-2-line`"
            :class="{ loading: exportLoading }"
            :disabled="exportLoading"
          ></IconButton>
        </div>
        <slot name="right-last-content"></slot>
      </div>
    </div>
    <slot name="bottom-content"></slot>
  </div>
</template>

<script>
import InputField from "@/v3components/shared/Form/InputField.vue"
import PillButton from "../Buttons/PillButton.vue"
import IconButton from "../Buttons/IconButton.vue"
import { reactive, onMounted, watch } from "vue"
import HelpCenterButton from "@/v3components/HelpCenterButton.vue"

export default {
  props: {
    tabs: {
      type: Array,
      default: null
    },
    showSearch: {
      type: Boolean,
      default: false
    },
    showSort: {
      type: Boolean,
      default: false
    },
    showExport: {
      type: Boolean,
      default: false
    },
    exportLoading: {
      type: Boolean,
      default: false
    },
    helpButton: {
      type: Boolean,
      default: false
    },
    helpButtonContentKey: {
      type: String,
      default: ""
    },
    searchVModel: {
      type: String,
      default: ""
    },
    showTopLeftSlot: {
      type: Boolean,
      default: false
    }
  },
  emits: ["tabClicked", "onSearch", "onSort", "onExport"],
  components: { PillButton, InputField, IconButton, HelpCenterButton },
  setup(props, { emit }) {
    const state = reactive({
      activeTab: null
    })

    onMounted(() => {
      setTimeout(() => {
        setActiveTab(props.tabs ? props.tabs[0] : state.activeTab)
      }, 500)
    })

    watch(
      () => props.tabs,
      (newTabs) => {
        if (newTabs && newTabs.length) {
          if (
            state.activeTab &&
            !newTabs.some((el) => el.value === state.activeTab.value)
          ) {
            setActiveTab(newTabs[0])
          }
        }
      }
    )

    const setActiveTab = (tab) => {
      state.activeTab = tab
      emit("tabClicked", tab)
    }
    const onSearch = (value) => {
      emit("onSearch", value)
    }
    const onSort = (value) => {
      emit("onSort", value)
    }
    const onExportClick = (value) => {
      emit("onExport", value)
    }

    return { setActiveTab, onSearch, onSort, onExportClick, state }
  }
}
</script>
