<template>
  <CContainer fluid class="appointment-pass-view datatable-container">
    <Loader :is-processing="state.isLoading" />

    <CRow class="mt-4">
      <CCol md="12">
        <ActionPanel
          :tabs="tabs"
          @tab-clicked="setActiveTab"
          show-export
          show-search
          :show-sort="activeTab !== 'prevSevenDays'"
          @on-sort="toggleFilter"
          @on-search="searchByQuery"
          @on-export="exportCSVDashboardData()"
        >
          <template #right-content>
            <CButton
              v-show="isActiveDashboardUpdate"
              class="py-1 px-2 py-sm-2 px-sm-3 me-4 btn btn-inactive"
              @click="
                $store.commit('dashboardTable/SET_DASHBOARD_UPDATES', false)
              "
            >
              <span class="d-flex align-items-center"
                ><i class="fi flaticon-pause me-2" />Pause Update</span
              >
            </CButton>
            <CButton
              v-show="!isActiveDashboardUpdate"
              class="py-2 px-3 me-4 btn bg-gradient-blue text-white"
              @click="resumeAptPassUpdate"
            >
              <span class="d-flex align-items-center"
                ><i class="fi flaticon-play me-2" />Resume Update</span
              >
            </CButton></template
          >
          <template #bottom-content>
            <AdultAptPassFilters v-if="state.isFilterVisible" />
          </template>
        </ActionPanel>
        <div
          class="status-guides-container justify-content-center justify-content-sm-end mb-1"
        >
          <PerPage
            v-if="pagination"
            :pagination="pagination"
            :showing-entries="true"
            @on-page-change="setPerPage"
          />
        </div>

        <DataTable
          v-if="appointmentPasses"
          add-table-classes="app-pass-datatable"
          :items="appointmentPasses"
          :fields="state.dataTableFields"
          :sorter="{ external: true }"
          column-filter
          :no-items-view="{
            noResults: 'No items available',
            noItems: 'No items available'
          }"
          @update:sorter-value="sortByColumn"
        >
          <template #search_column>
            <div class="sort-icon-v3">
              <i class="ri-search-line" @click="toggleSearchByColumn"></i>
            </div>
          </template>

          <template #user="{ item }">
            <td>
              <UserAptPass :appointments="true" :pass="item" />
            </td>
          </template>
          <template #user-filter>
            <input
              v-show="state.filter"
              v-model="state.columnSearchKeys.user.value"
              placeholder="Search by name"
              class="form-control form-control-sm my-2"
              @input="searchByQuery($event.target.value, 'column')"
            />
          </template>

          <template #recurrence_appointment_pass="{ item }">
            <td>
              <div v-if="item.recurrence_appointment_pass">
                <div>
                  <b>{{
                    $helpers.transformDate(item.for_date, "MMM DD YYYY")
                  }}</b>
                </div>
                <div
                  class="d-flex v3base-gray"
                  v-if="item.recurrence_appointment_pass.recurrence_type"
                >
                  <div class="me-2 mt-sm-1">
                    {{
                      getRecurrenceOption(
                        item.recurrence_appointment_pass.recurrence_type
                      )
                    }}
                  </div>
                  <div
                    v-if="item.recurrence_appointment_pass.recurrence_days"
                    class="weekdays-options-container d-flex"
                  >
                    <div
                      v-for="(day, index) in JSON.parse(
                        item.recurrence_appointment_pass.recurrence_days
                      ).days"
                      :key="index"
                      class="weekday-box active me-1"
                    >
                      {{ day.substring(0, 2) }}
                    </div>
                  </div>
                </div>
                <div
                  class="font-smaller v3base-gray"
                  v-if="activeTab == 'nextSevenDays'"
                >
                  <div
                    v-if="item.recurrence_appointment_pass.recurrence_end_at"
                    class=""
                  >
                    {{
                      $helpers.transformDate(
                        item.recurrence_appointment_pass.for_date,
                        "MMM D YYYY"
                      )
                    }}
                    <i class="ri-arrow-right-s-line v3base-blue"></i>
                    {{
                      $helpers.transformDate(
                        item.recurrence_appointment_pass.recurrence_end_at,
                        "MMM D YYYY"
                      )
                    }}
                  </div>
                </div>
                <div v-else>
                  <div v-if="item.for_date" class="font-smaller v3base-gray">
                    {{ $helpers.transformDate(item.for_date, "MMM D YYYY") }}
                    <i class="ri-arrow-right-s-line v3base-blue"></i>
                    {{
                      $helpers.transformDate(
                        item.recurrence_appointment_pass.recurrence_end_at,
                        "MMM D YYYY"
                      )
                    }}
                  </div>
                </div>
              </div>
              <div v-else>
                <b class="mt-0">{{
                  item.for_date
                    ? $helpers.transformDate(item.for_date, "MMM D YYYY")
                    : ""
                }}</b>
                <div class="v3base-gray">Does not repeat</div>
              </div>
            </td>
          </template>
          <template #recurrence_appointment_pass-filter>
            <input
              v-show="state.filter"
              v-model="state.columnSearchKeys.recurrence_appointment_pass.value"
              placeholder="Search by date"
              class="form-control form-control-sm my-2"
              @input="searchByQuery($event.target.value, 'column')"
            />
          </template>

          <template #period="{ item }">
            <td>
              <div>
                <b v-if="item.period">{{ item.period.name }}</b>
                <div class="v3base-gray">
                  {{
                    item.for_date
                      ? $helpers.transformDate(item.for_date, "h:mm A")
                      : ""
                  }}
                </div>
              </div>
            </td>
          </template>
          <template #period-filter>
            <input
              v-show="state.filter"
              v-model="state.columnSearchKeys.period.value"
              placeholder="Search by period name"
              class="form-control form-control-sm my-2"
              @input="searchByQuery($event.target.value, 'column')"
            />
          </template>

          <template #from="{ item }">
            <td class="pass-time-info">
              <div class="d-flex">
                <b
                  v-if="
                    item.from_type && item.from_type === 'App\\Models\\User'
                  "
                  >{{
                    item.from
                      ? item.from.first_name + " " + item.from.last_name
                      : "-"
                  }}</b
                >
                <b v-else>{{ item.from ? item.from.name : "-" }}</b>
                <b
                  v-if="
                    item.acknowledged_by_user &&
                    item.from &&
                    item.acknowledged_by_user.id === item.from.id
                  "
                  ><span class="checked-icon ms-2"
                /></b>
              </div>
            </td>
          </template>
          <template #from-filter>
            <input
              v-show="state.filter"
              v-model="state.columnSearchKeys.from.value"
              placeholder="Search by name"
              class="form-control form-control-sm my-2"
              @input="searchByQuery($event.target.value, 'column')"
            />
          </template>

          <template #to="{ item }">
            <td class="pass-time-info">
              <b v-if="item.to && item.to === 'App\\Models\\User'">{{
                item.to ? item.to.first_name + " " + item.to.last_name : "-"
              }}</b>
              <b v-else>{{ item.to ? item.to.name : "-" }}</b>
              <b
                v-if="
                  item.acknowledged_by_user &&
                  item.to &&
                  item.acknowledged_by_user.id === item.to.id
                "
                ><span class="checked-icon ms-2"
              /></b>
            </td>
          </template>
          <template #to-filter>
            <input
              v-show="state.filter"
              v-model="state.columnSearchKeys.to.value"
              placeholder="Search by name"
              class="form-control form-control-sm my-2"
              @input="searchByQuery($event.target.value, 'column')"
            />
          </template>

          <template #created_by_user="{ item }">
            <td>
              <div v-if="item.created_by_user">
                <b>{{
                  item.created_by_user.first_name +
                  " " +
                  item.created_by_user.last_name
                }}</b>
                <p v-if="item.created_at">
                  {{ $helpers.transformDate(item.created_at, "MM/D/YY") }}
                  {{ $helpers.transformDate(item.created_at, "hh:mm A") }}
                </p>
              </div>
            </td>
          </template>
          <template #created_by_user-filter>
            <input
              v-show="state.filter"
              v-model="state.columnSearchKeys.created_by_user.value"
              placeholder="Search by name"
              class="form-control form-control-sm my-2"
              @input="searchByQuery($event.target.value, 'column')"
            />
          </template>

          <template #comments="{ item }">
            <td class="v3comments-column">
              <p class="mb-1">
                <b
                  >{{
                    item.created_by_user && item.created_by_user.role_id == 1
                      ? "Requested "
                      : "Made "
                  }}
                </b>
                <span
                  v-html="
                    $helpers.getHTMLDateTime(
                      item.created_at,
                      'MMM DD YYYY',
                      'h:mm A'
                    )
                  "
                />
              </p>
              <p
                v-if="
                  item.acknowledged_by_teacher_at &&
                  item.created_by_user &&
                  item.created_by_user.role_id != 1
                "
                class="mb-1"
              >
                <b>Acknowledged </b>
                <span
                  v-html="
                    $helpers.getHTMLDateTime(
                      item.acknowledged_by_teacher_at,
                      'MMM DD YYYY',
                      'h:mm A'
                    )
                  "
                />
              </p>
              <p
                v-if="
                  item.confirmed_by_teacher_at &&
                  item.acknowledged_by_teacher_at &&
                  item.created_by_user &&
                  item.created_by_user.role_id == 1
                "
                class="mb-1"
              >
                <b>Confirmed </b>
                <span
                  v-html="
                    $helpers.getHTMLDateTime(
                      item.confirmed_by_teacher_at,
                      'MMM DD YYYY',
                      'h:mm A'
                    )
                  "
                />
              </p>
              <p v-if="item.canceled_at && item.canceled_by_user" class="mb-1">
                <b
                  >Canceled by {{ item.canceled_by_user.first_name }}
                  {{ item.canceled_by_user.last_name }} at
                </b>
                <span
                  v-html="
                    $helpers.getHTMLDateTime(
                      item.canceled_at,
                      'MMM DD YYYY',
                      'h:mm A'
                    )
                  "
                />
              </p>
              <PassComments
                :pass="item"
                commentable-type="App\Models\AppointmentPass"
                :only-latest-comment="
                  activeTab === 'prevSevenDays' ? true : false
                "
                :style="activeTab === 'nextSevenDays' ? 'cursor: pointer' : ''"
              />
            </td>
          </template>
          <template #comments-filter>
            <input
              v-show="state.filter"
              v-model="state.columnSearchKeys.comments.value"
              placeholder="Search by latest comment"
              class="form-control form-control-sm my-2"
              @input="searchByQuery($event.target.value, 'column')"
            />
          </template>

          <template #actions="{ item }">
            <td class="text-end">
              <AptPassAction
                :pass="item"
                :is-pass-beyond="activeTab === 'prevSevenDays'"
              />
            </td>
          </template>
          <template #actions-filter>
            <div v-show="state.filter" />
          </template>
        </DataTable>

        <Pagination
          v-if="
            pagination.pages &&
            pagination.pages > 1 &&
            pagination.per_page.value != 75
          "
          class="cs-pagination mt-4"
          :dots="false"
          :double-arrows="true"
          :active-page="pagination.activePage"
          :pages="pagination.pages"
          size="lg"
          align="center"
          @update:active-page="setActivePage"
        >
        </Pagination>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import { onMounted, computed, reactive, inject, watch, onUnmounted } from "vue"
import { useStore } from "vuex"
import Loader from "@/v3components/shared/Loader/Loader.vue"
import ActionPanel from "@/v3components/shared/DataTable/ActionPanel.vue"
import tableConfig from "./appointmentsTableConfig.js"
import PerPage from "@/v3components/shared/DataTable/Perpage.vue"
import DataTable from "@/v3components/shared/DataTable/DataTable.vue"
import Pagination from "@/v3components/shared/DataTable/Pagination.vue"
import UserAptPass from "./UserAptPass.vue"
import PassComments from "@/v3components/shared/PassComponents/PassComments.vue"
import AdultAptPassFilters from "./AdultAptPassFilters.vue"
import AptPassAction from "./AptPassAction.vue"
import download from "@/helpers/downloadCSV"
import helpers from "@/helpers/index.js"

export default {
  name: "AdultAppointmentPassTable",
  components: {
    Loader,
    ActionPanel,
    PerPage,
    DataTable,
    Pagination,
    UserAptPass,
    PassComments,
    AdultAptPassFilters,
    AptPassAction
  },

  setup() {
    const store = useStore()

    const modal = inject("modal")

    const state = reactive({
      ...tableConfig,
      isLoading: false,
      waiting: false,
      tempDashboardActiveType: null,
      filter: false,
      isFilterVisible: false
    })

    const periods = computed(() => store.getters["periods/formListStudents"])
    const editablePass = computed(
      () => store.getters["adultAptPass/editablePass"]
    )
    const comentablePass = computed(
      () => store.getters["adultAptPass/commentablePass"]
    )
    const activeTab = computed(() => store.getters["adultAptPass/activeTab"])
    const pagination = computed(() => store.getters["adultAptPass/pagination"])
    const searchQuery = computed(
      () => store.getters["adultAptPass/searchQuery"]
    )
    const appointmentPasses = computed(
      () => store.getters["adultAptPass/appointmentPasses"]
    )

    const currentUser = computed(() => store.getters["authentication/user"])
    const isActiveDashboardUpdate = computed(
      () => store.getters["dashboardTable/isActiveDashboardUpdate"]
    )
    const dashboardActiveType = computed(
      () => store.getters["dashboardTable/getType"]
    )

    const commentablePassUserName = computed(() => {
      return comentablePass.value && comentablePass.value.user
        ? comentablePass.value.user.first_name +
            " " +
            comentablePass.value.user.last_name
        : null
    })

    onMounted(() => {
      store.commit("adultAptPass/SET_ACTIVE_TAB", "nextSevenDays")
      store.dispatch("schools/getActiveModules", {
        clearCacheEntry: true
      })
      if (isActiveDashboardUpdate?.value) {
        resetSearchQuery()
        resumeAptPassUpdate()
      }
      initLazyLoad()
      store.dispatch("passes/getPassesTimeSetting")

      state.tempDashboardActiveType =
        dashboardActiveType.value != "appointment"
          ? dashboardActiveType.value
          : "my"
      store.commit("dashboardTable/UPDATE_TYPE", "appointment")
    })

    onUnmounted(() => {
      store.commit("dashboardTable/UPDATE_TYPE", state.tempDashboardActiveType)
    })

    const searchByQuery = (value, type) => {
      if (window.searchByQueryTimeout) clearTimeout(window.searchByQueryTimeout)

      window.searchByQueryTimeout = setTimeout(() => {
        handleQuerySearch(value, type)
      }, 1000)
    }

    const handleQuerySearch = (value, type) => {
      const query = value.toString()
      if (
        query.replace(/\s/g, "").length > 0 ||
        query.replace(/\s/g, "").length === 0
      ) {
        store.commit("adultAptPass/SET_SEARCH_QUERY", "")
        if (type && type === "column") {
          state.columnSearchKeys.globalQuery = ""
          for (const key in state.columnSearchKeys) {
            if (Object.hasOwnProperty.call(state.columnSearchKeys, key)) {
              const columnData = state.columnSearchKeys[key]
              if (columnData.value) {
                columnData.column.forEach((col) => {
                  if (searchQuery.value) {
                    switch (col) {
                      case "user.name":
                        store.commit(
                          "adultAptPass/SET_SEARCH_QUERY",
                          searchQuery.value +
                            `${col}:${columnData.value}|user.sortable_name:${columnData.value}`
                        )
                        break
                      case "from.name":
                        store.commit(
                          "adultAptPass/SET_SEARCH_QUERY",
                          searchQuery.value +
                            `,${col}:${columnData.value}|from.sortable_name:${columnData.value}`
                        )
                        break
                      case "to.name":
                        store.commit(
                          "adultAptPass/SET_SEARCH_QUERY",
                          searchQuery.value +
                            `,${col}:${columnData.value}|to.sortable_name:${columnData.value}`
                        )
                        break
                      case "created_by_user.name":
                        store.commit(
                          "adultAptPass/SET_SEARCH_QUERY",
                          searchQuery.value +
                            `,${col}:${columnData.value}|created_by_user.sortable_name:${columnData.value}`
                        )
                        break
                      default:
                        store.commit(
                          "adultAptPass/SET_SEARCH_QUERY",
                          searchQuery.value + `,${col}:${columnData.value}`
                        )
                        break
                    }
                  } else {
                    switch (col) {
                      case "user.name":
                        store.commit(
                          "adultAptPass/SET_SEARCH_QUERY",
                          `${col}:${columnData.value}|user.sortable_name:${columnData.value}`
                        )
                        break
                      case "from.name":
                        store.commit(
                          "adultAptPass/SET_SEARCH_QUERY",
                          `${col}:${columnData.value}|from.sortable_name:${columnData.value}`
                        )
                        break
                      case "to.name":
                        store.commit(
                          "adultAptPass/SET_SEARCH_QUERY",
                          `${col}:${columnData.value}|to.sortable_name:${columnData.value}`
                        )
                        break
                      case "created_by_user.name":
                        store.commit(
                          "adultAptPass/SET_SEARCH_QUERY",
                          `${col}:${columnData.value}|created_by_user.sortable_name:${columnData.value}`
                        )
                        break
                      default:
                        store.commit(
                          "adultAptPass/SET_SEARCH_QUERY",
                          `${col}:${columnData.value}`
                        )
                        break
                    }
                  }
                })
              }
            }
          }
        } else {
          resetSearchQuery()
          state.columnSearchKeys.globalQuery = query
          if (query) {
            store.commit("adultAptPass/SET_SEARCH_QUERY", query)
          }
        }
        store.commit("dashboardTable/SET_DASHBOARD_UPDATES", false)
        store.dispatch("adultAptPass/getAppointmentPasses")
      }
    }

    const resetSearchQuery = () => {
      store.commit("dashboardTable/SET_DASHBOARD_UPDATES", true)
      store.commit("adultAptPass/SET_SEARCH_QUERY", "")
      state.columnSearchKeys = {
        globalQuery: "",
        user: { value: "", column: ["user.name"], sortBy: "user.last_name" },
        recurrence_appointment_pass: {
          value: "",
          column: ["search_date_string"],
          sortBy: "for_date"
        },
        period: { value: "", column: ["period.name"], sortBy: "period.name" },
        from: { value: "", column: ["from.name"], sortBy: "from.name" },
        to: { value: "", column: ["to.name"], sortBy: "to.name" },
        created_by_user: {
          value: "",
          column: ["created_by_user.name"],
          sortBy: "created_by_user.name"
        },
        comments: {
          value: "",
          column:
            activeTab.value == "prevSevenDays"
              ? ["reason"]
              : ["latest_comment.comment"],
          sortBy: "latest_comment.comment"
        }
      }
    }

    const sortByColumn = (column) => {
      const columnName = state.columnSearchKeys[column.column]
        ? state.columnSearchKeys[column.column].sortBy
        : column.column
      store.commit(
        "adultAptPass/SET_SORT_BY",
        `${columnName}:${column.asc ? "asc" : "desc"}`
      )
      store.commit("dashboardTable/SET_DASHBOARD_UPDATES", false)
    }

    const onPassEdit = (pass) => {
      store.commit("adultAptPass/SET_EDITABLE_PASS", pass)
      helpers.scrollToTop()
    }

    const exportCSVDashboardData = () => {
      state.isLoading = true
      store
        .dispatch("adultAptPass/getCSVAppointmentPasses")
        .then((response) => {
          if (response.data) {
            download.CSVExport(response.data, "appointment-passes")
          }
          state.isLoading = false
        })
        .catch(() => {
          state.isLoading = false
        })
    }

    const initLazyLoad = () => {
      const mainCnt = document.getElementsByClassName(
        "main-scroll-container"
      )[0]
      if (mainCnt) {
        mainCnt.addEventListener("scroll", () => {
          if (
            pagination.value.per_page.value == 75 &&
            pagination.value.total > appointmentPasses.value.length &&
            !state.waiting
          ) {
            if (
              mainCnt.offsetHeight + mainCnt.scrollTop >=
              mainCnt.scrollHeight - 1000
            ) {
              store.commit(
                "adultAptPass/SET_ACTIVE_PAGE",
                pagination.value.activePage + 1
              )
              state.waiting = true
              setTimeout(() => {
                state.waiting = false
              }, 1000)
            }
          }
        })
      }
    }

    const setPerPage = (option) => {
      store.commit("adultAptPass/SET_PER_PAGE", option)
    }

    const resumeAptPassUpdate = () => {
      store.commit("dashboardTable/SET_DASHBOARD_UPDATES", true)
      store.dispatch("adultAptPass/getAppointmentPasses")
    }

    const tabs = [
      { label: "Next 7 Days", value: "nextSevenDays" },
      { label: "Beyond 7 Days", value: "prevSevenDays" }
    ]

    const setActiveTab = (tab) => {
      state.isFilterVisible = false
      store.commit("adultAptPass/SET_ACTIVE_TAB", tab.value)
    }

    const toggleSearchByColumn = () => {
      state.filter = !state.filter
    }

    const setActivePage = (page) => {
      helpers.scrollToTop()
      store.commit("adultAptPass/SET_ACTIVE_PAGE", page)
    }

    const toggleFilter = () => {
      state.isFilterVisible = !state.isFilterVisible
    }

    watch(
      () => modal.options.action,
      (isAction) => {
        if (isAction == "createAppointments") {
          resumeAptPassUpdate()
        }
      }
    )

    const getRecurrenceOption = (option) => {
      switch (option) {
        case "every_two_weeks":
          return "Every other week"
        default:
          return option
      }
    }

    return {
      state,
      periods,
      editablePass,
      comentablePass,
      activeTab,
      pagination,
      searchQuery,
      appointmentPasses,
      currentUser,
      isActiveDashboardUpdate,
      dashboardActiveType,
      commentablePassUserName,
      searchByQuery,
      handleQuerySearch,
      resetSearchQuery,
      sortByColumn,
      onPassEdit,
      exportCSVDashboardData,
      resumeAptPassUpdate,
      setPerPage,
      tabs,
      setActiveTab,
      toggleSearchByColumn,
      setActivePage,
      toggleFilter,
      getRecurrenceOption
    }
  }
}
</script>
